import { Droppable } from "react-beautiful-dnd";
import compareIcon from "../../../assets/images/compare_icon.svg";
import { COMPARE_ANSWER_CODE, MULTIPLE_CHOICE_ANSWER_CODE, ONE_CHOICE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, TRUE_FALSE_ANSWER_CODE } from "../../../utils/questionsHelper";
import cross from "../../../assets/images/symbols/cross_grey.svg";

import styles from "./styles.module.scss";
import cn from "classnames";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const SomeAnswerInputs = ({
   type,
   item,
   answerIdx,
   inputsData,
   setInputsData,
   onChangeAnswerText,
   onSelectAnswer,
   removeAnswerById,
   setChangedLessonFlag,
   isFieldEmpty,
   setIsFieldEmpty,
   isGrayText,
   isGrayTextOption,
   isNotDefaultLanguage
}) => {
   /*    Types codes and correspondence: 
          0: One choice,        5: Stars 1-10
          1: Multiple choice    6: True/False   
          2: File upload        7: Compare 
          3: Written answer     8: Sequence,  
          4: Rate 1-10          9: Video Answer 
      */

   const t = useFindTranslationsByString();

   const onSelectAnswerRadio = (id) => {
      setChangedLessonFlag("changed"); 
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: prevInputsData.answers.map((answer) =>
            answer.id === id
               ? { ...answer, isCorrect: true }
               : { ...answer, isCorrect: false }
         ),
      }));
   };

   const onChangeAnswerTextSequence = (text, id) => {
      setChangedLessonFlag("changed"); 
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         sequence: prevInputsData.answers.map((answer) => {
            const updatedAnswer =
               answer.id === id ? { ...answer, content: text } : answer;
            return updatedAnswer.content;
         }),
         answers: prevInputsData?.answers?.map((answer) =>
            answer.id === id ? { ...answer, content: text } : answer
         ),
      }));
   };

   const onChangeAnswerCompareTitle = (text, id, answerKey) => {
      setChangedLessonFlag("changed"); 
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: prevInputsData?.answers?.map((answer) =>
            answer.id === id
               ? { ...answer, matchingOption: answerKey, content: text }
               : answer
         ),
      }));
   };

   const onChangeAnswerCompareOption = (text, id, answerKey) => {
      setChangedLessonFlag("changed"); 
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         options: prevInputsData?.options?.map((option, idx) =>
            idx === answerKey ? text : option
         ),
      }));
   };

   if (type === MULTIPLE_CHOICE_ANSWER_CODE) {
      return (
         <div key={answerIdx} className={cn(styles.answer, styles.no_padding)}>
            <div
               className={cn(
                  styles.answer_block,
                  styles.label,
                  item?.isCorrect ? styles.answer_block_isSelected : '',
                  isFieldEmpty && !item?.content ? styles.error : '',
                  isGrayText ? styles.prevInput : ''
               )}
            >
               <div className={styles.checkbox}>
                  <input
                     type="checkbox"
                     checked={item?.isCorrect}
                     onChange={() => onSelectAnswer(item?.id)}
                     disabled={isNotDefaultLanguage}
                  />
               </div>
               <input
                  value={item?.content}
                  placeholder={t("Enter the answer")}
                  onChange={(e) =>
                     onChangeAnswerText(e?.target?.value, item?.id)
                  }
               />

               {!isNotDefaultLanguage &&
                  <button className={styles.cross} onClick={()=> removeAnswerById(item.id)}>
                     <img src={cross} alt="" />
                  </button>
               }

            </div>
         </div>
      );
   }

   if (type === ONE_CHOICE_ANSWER_CODE) {
      return (
         <div key={answerIdx} className={cn(
            styles.answer,
            styles.no_padding,
            isFieldEmpty && !item?.content?.length ? styles.error : '',
            isGrayText ? styles.prevInput : ''
         )}>
            <div
               className={cn(
                  styles.answer_block,
                  styles.label,
                  item?.isCorrect ? styles.answer_block_isSelected : ''
               )}
            >
               <div className={cn(styles.checkbox, styles.radio)}>
                  <input
                     type="checkbox"
                     checked={item?.isCorrect}
                     onChange={() => onSelectAnswerRadio(item?.id)}
                     className={styles.radioButton}
                     disabled={isNotDefaultLanguage}
                  />
                  <div className={styles.radioButtonLabel}></div>
               </div>
               <input
                  value={item?.content}
                  placeholder={t("Enter the answer")}
                  onChange={(e) =>
                     onChangeAnswerText(e?.target?.value, item?.id)
                  }
               />
               {!isNotDefaultLanguage &&
               <button className={styles.cross} onClick={()=> removeAnswerById(item.id)}>
                  <img src={cross} alt="" />
               </button>
               }
            </div>
          
         </div>
      );
   }

   if (type == TRUE_FALSE_ANSWER_CODE) {
      return (
         <div key={answerIdx} className={styles.answer}>
            <div
               className={cn(
                  styles.answer_block,
                  item?.isCorrect ? styles.answer_block_isSelected : '',
                  isGrayText ? styles.prevInput : ''
               )}
            >
               <div className={cn(styles.checkbox, styles.radio)}>
                  <input
                     type="checkbox"
                     id={item.id}
                     checked={item?.isCorrect}
                     onChange={() => onSelectAnswerRadio(item?.id)}
                     className={styles.radioButton}
                     disabled={isNotDefaultLanguage}
                  />
               </div>

               <label className={styles.radio_label} htmlFor={item.id}>
                  {t(item?.content)}
               </label>
            </div>
         </div>
      );
   }

   if (type === COMPARE_ANSWER_CODE) {
      return (
         <div key={answerIdx} className={styles.compare_wrapper}>
            <div className={cn(styles.answer, styles.compare_answer)}>
               <div
                  className={cn(
                     styles.answer_block,
                     item?.isCorrect ? styles.answer_block_isSelected : '',
                     isFieldEmpty && !item?.content?.length ? styles.error : '',
                     isGrayTextOption ? styles.prevInput : ''
                  )}
               >
                  <input
                     value={inputsData?.options[answerIdx]}
                     className={styles.compare_input}
                     placeholder={t("Enter the title")}
                     onChange={(e) =>
                        onChangeAnswerCompareOption(
                           e?.target?.value,
                           item?.id,
                           answerIdx
                        )
                     }
                  />
               </div>
            </div>
            <img src={compareIcon} alt="compare icon" />
            <div
               key={answerIdx}
               className={cn(styles.answer, styles.compare_answer)}
            >
               <div
                  className={cn(
                     styles.answer_block,
                     item?.isCorrect && styles.answer_block_isSelected,
                     isFieldEmpty && !item?.content?.length ? styles.error : '',
                     isGrayText ? styles.prevInput : ''
                  )}
               >
                  <input
                     value={item?.content}
                     placeholder={t("Enter the answer")}
                     onChange={(e) =>
                        onChangeAnswerCompareTitle(
                           e?.target?.value,
                           item?.id,
                           answerIdx
                        )
                     }
                  />
               </div>
            </div>
            {!isNotDefaultLanguage &&
               <button className={styles.cross} onClick={()=> removeAnswerById(item.id, answerIdx)}>
                  <img src={cross} alt="" />
               </button>
            }
         </div>
      );
   }

   if (type === SEQUENCE_ANSWER_CODE) {
      return (
         <div key={answerIdx} className={cn(styles.answer, styles.no_padding)}>
            <div
               className={cn(
                  styles.answer_block,
                  styles.label,
                  item?.isCorrect ? styles.answer_block_isSelected : '',
                  isFieldEmpty && !item?.content?.length ? styles.error : '',
                  isGrayText ? styles.prevInput : ''
               )}
            >
               {answerIdx + 1}
               <input
                  value={item?.content}
                  placeholder={t("Enter the answer")}
                  onChange={(e) =>
                     onChangeAnswerTextSequence(e?.target?.value, item?.id)
                  }
               />
               {!isNotDefaultLanguage &&
                  <button className={styles.cross} onClick={()=> removeAnswerById(item.id, answerIdx)}>
                     <img src={cross} alt="" />
                  </button>
               }
            </div>
         </div>
      );
   }

   // Return nothing in case of type is not here 
   return <></>;
};

export default SomeAnswerInputs;
