import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";
import cn from "classnames";
import {
   CERTIFICATE_FONTS,
   CERTIFICATE_GLOBAL_TYPES,
   CERTIFICATE_GLOBAL_TYPE_FIRST,
   CERTIFICATE_GLOBAL_TYPE_SECOND,
   CERTIFICATE_TABS,
   CERTIFICATE_TEMPLATES_FIRST,
   CERTIFICATE_TEMPLATES_SECOND,
   STYLES_TAB,
   TEMPLATES_TAB,
   colorPalettes,
} from "../../../constants/courses";
import TabButton from "../../../components/TabButton/TabButton";

import Dropdown from "../../../components/Dropdown/Dropdown";
import CertificateTemplate from "../../../components/CertificateTemplate/CertificateTemplate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const CertificateTab = ({
   course,
   currentSertificateTemplate,
   setCurrentSertificateTemplate,
   setFormData,
}) => {
   const currentCompanyLogo = useSelector((state) => state.sidebar.companyLogo);
   const currentCompanyName = useSelector((state) => state.sidebar.companyName);
   const [selectedMode, setSelectedMode] = useState(TEMPLATES_TAB);

   const t = useFindTranslationsByString();

   const mapDefaultTemplate = (certificate) => ({
      ...certificate,
      course_name: course?.name,
      result: "Exelent",
      company_logo: currentCompanyLogo,
      company_name: currentCompanyName,
      serial_number: "№UD2047895487569854",
      end_date: "February 06, 2023",
      color_palette: currentSertificateTemplate?.color_palette || "1",
      font_family: currentSertificateTemplate?.font_family,
      language: currentSertificateTemplate?.language,
   });

   const [certificateTemplatesFirst, setCertificateTemplatesFirst] = useState([]);
   const [certificateTemplatesSecond, setCertificateTemplatesSecond] = useState([]);
   const [certificateGlobalTypes, setCertificateGlobalTypes] = useState([]);

   const handleColorClick = (palette) => {
      setCurrentSertificateTemplate({
         ...currentSertificateTemplate,
         color_palette: palette,
      });
   };

   useEffect(() => {
      setCurrentSertificateTemplate({
         ...currentSertificateTemplate,
         course_name: course?.name,
         result: "Exelent",
         company_logo: currentCompanyLogo,
         company_name: currentCompanyName,
         serial_number: "№UD2047895487569854",
         end_date: "February 06, 2023",
         color_palette: course?.certificateTemplate?.colorPalette || "1",
         name: course?.certificateTemplate?.name || "first",
         type: course?.certificateTemplate?.type || "first_type_1",
         language: course?.certificateTemplate?.language || "en",
         font_family: course?.certificateTemplate?.fontFamily || "en",
      });
   }, [course]);

   useEffect(() => {
      setCertificateTemplatesFirst(
         CERTIFICATE_TEMPLATES_FIRST.map(mapDefaultTemplate)
      );
      setCertificateTemplatesSecond(
         CERTIFICATE_TEMPLATES_SECOND.map(mapDefaultTemplate)
      );
      setCertificateGlobalTypes(
         CERTIFICATE_GLOBAL_TYPES.map(mapDefaultTemplate)
      );
   }, [currentSertificateTemplate]);

   const setLanguage = (lang) => {
      setCurrentSertificateTemplate({
         ...currentSertificateTemplate,
         language: lang
      })
   }


   return (
      <div className={cn(styles.certificateTab, "editor_bg")}>
         <div className={styles.center}>
            <div className={styles.center_content}>
               <div className={styles.big_certificate_template}>
                  <CertificateTemplate
                     size="10"
                     data={currentSertificateTemplate}
                     key={"big_template"}
                  />
               </div>
            </div>
         </div>
         <div className={styles.right}>
            <div className={styles.right_header}>
               <div className={styles.content}>
                  <div>{t("Language")}</div>
                  <div className={styles.language}>
                     <button
                        className={`${styles.button_language} ${styles.current}`}
                        type="button"
                     >
                        {currentSertificateTemplate?.language}
                     </button>
                     <ul className={styles.language_list}>
                            <li className={styles.language_list_item}>
                              <button
                                 className={styles.button_language}
                                 type="button"
                                 onClick={()=> setLanguage('en')}
                              >
                                 EN
                              </button>
                           </li>
                        <li className={styles.language_list_item}>
                           <button
                              className={styles.button_language}
                              type="button"
                              onClick={()=> setLanguage('uk')}
                           >
                              UA
                           </button>
                        </li>
                        <li className={styles.language_list_item}>
                           <button
                              className={styles.button_language}
                              type="button"
                              onClick={()=> setLanguage('ru')}
                           >
                              RU
                           </button>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className={styles.right_mode_btns}>
               {CERTIFICATE_TABS?.map((item, key) => (
                  <TabButton
                     item={item}
                     key={key}
                     mode={selectedMode}
                     setMode={setSelectedMode}
                     padding={"10px 0px"}
                  />
               ))}
            </div>
            {selectedMode === TEMPLATES_TAB && (
               <>
                  <div className={styles.main_templates_wrapper}>
                     {certificateGlobalTypes.map((type) => (
                        <div className={styles.grid_item} key={type?.id}>
                           <CertificateTemplate
                              maxWidth={"100vw"}
                              size={'1'}
                              data={type}
                              onClick={() =>
                                 setCurrentSertificateTemplate(type)
                              }
                              isActive={
                                 currentSertificateTemplate.name === type.name
                              }
                           />
                        </div>
                     ))}
                  </div>
                  <div className={cn(styles.title, styles.title_with_padding)}>
                     {t("Type")}
                  </div>
                  <div className={styles.right_content}>
                     {currentSertificateTemplate.name ===
                        CERTIFICATE_GLOBAL_TYPE_FIRST &&
                        certificateTemplatesFirst.map((item, index) => (
                           <div
                              className={cn(styles.grid_item)}
                              key={`first${item.id}`}
                           >
                              <CertificateTemplate
                                 data={item}
                                 size={'1'}
                                 onClick={() =>
                                    setCurrentSertificateTemplate(item)
                                 }
                                 isActive={
                                    currentSertificateTemplate.type ===
                                    item.type
                                 }
                              />
                           </div>
                        ))}

                     {currentSertificateTemplate.name ===
                        CERTIFICATE_GLOBAL_TYPE_SECOND &&
                        certificateTemplatesSecond.map((item, index) => (
                           <div
                              className={styles.grid_item}
                              key={`second_${item.id}`}
                           >
                              <CertificateTemplate
                                 size={'1'}
                                 onClick={() =>
                                    setCurrentSertificateTemplate(item)
                                 }
                                 data={item}
                                 isActive={
                                    currentSertificateTemplate.type ===
                                    item.type
                                 }
                              />
                           </div>
                        ))}
                  </div>
               </>
            )}

            {selectedMode === STYLES_TAB && (
               <div className={styles.styles_block}>
                  <div className={styles.title}>{t("Colour presets")}</div>
                  <div className={styles.color_paletts}>
                     <div className={styles.palette_left}>
                        {colorPalettes.slice(0, 3).map((item, index) => (
                           <img
                              key={index}
                              src={item.img}
                              className={
                                 currentSertificateTemplate.color_palette ===
                                 item.label
                                    ? styles.active_palette
                                    : ""
                              }
                              alt=""
                              onClick={() => handleColorClick(item.label)}
                           />
                        ))}
                     </div>
                     <div>
                        {colorPalettes.slice(3).map((item, index) => (
                           <img
                              key={index}
                              src={item.img}
                              className={
                                 currentSertificateTemplate.color_palette ===
                                 item.label
                                    ? styles.active_palette
                                    : ""
                              }
                              alt=""
                              onClick={() => handleColorClick(item.label)}
                           />
                        ))}
                     </div>
                  </div>

                  <div className={styles.title}>{t("Text")}</div>

                  <Dropdown
                     maxHeight={"200px"}
                     data={CERTIFICATE_FONTS.map(font => font.replace(/_/g, ''))}
                     placeholder={t("Font")}
                     value={currentSertificateTemplate.font_family}
                     onChange={(value) => {
                        setCurrentSertificateTemplate({
                           ...currentSertificateTemplate,
                           font_family: value
                        })
                     }}
                  />
               </div>
            )}
         </div>
      </div>
   );
};

export default CertificateTab;
