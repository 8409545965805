import React from 'react';
import styles from "./ColorsPicker.module.scss";
import {COLOR_PRESETS} from "../../constants/courses";
import cn from "classnames";

const ColorsPicker = ({onClick, activeColorId}) => {
    return (
        <div className={styles.colors_list}>
            {COLOR_PRESETS?.map((item, key) => (
                <div
                    key={key}
                    onClick={() => onClick(item)}
                    className={cn(
                        styles.colors_list_item,
                        activeColorId === item?.id &&
                        styles.colors_list_item_active
                    )}
                >
                    <div style={{background: item.color}}></div>
                </div>
            ))}
        </div>
    );
};

export default ColorsPicker;