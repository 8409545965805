import { BIGGER_DATA_LIMIT, DEFAULT_DATA_LIMIT } from "../../constants/user";
import Base from "./Base";
import {USERS_TESTS_SORTING_COLUMN} from "../../constants/tests";

export default class KnowledgeCheckAPI extends Base {
  getUsersTest(
    limit,
    page = 1,
    companyID,
    isChecked,
    status,
    searchedValue,
    isShowRelatedToYouTests,
    isAdminAsStudent,
    isYourTests,
    isMentor,
    usersIds,
    dateFilter,
    serialization_group,
    teamIds,
    order,
    order_by
  ) {
    let url = `api/v1/user-test?limit=${limit}&page=${page}&filter[company]=${companyID}&filter[is_checked]=${isChecked}`;

    if (status) {
      url += `&filter[status]=${status}`;
    }

    if (isYourTests) {
      url += `&filter[your_user_tests]=${1}`;
    }

    if (isShowRelatedToYouTests) {
      url += `&filter[get_user_tests_of_available_course]=${1}`;
      //  Don't show tests from courses where you are as student
      url += `&filter[get_user_tests_when_you_are_admin_as_student]=${0}`;
    }

    if (isAdminAsStudent) {
      url += `&filter[get_user_tests_when_you_are_admin_as_student]=${1}`;
    }

    if (isMentor) {
      url += `&filter[get_user_tests_of_members_of_teams_where_you_are_mentor]=${1}`;
    }

    if (usersIds?.length) {
      url += `&filter[user_ids]=${JSON.stringify(usersIds)}`;
    }

    if (teamIds?.length) {
      url += `&filter[team_ids]=${JSON.stringify(teamIds)}`;
    }

    if (dateFilter?.length) {
      url += `&filter[available_date_range]=${dateFilter}`;
    }

    if (searchedValue) {
      url += `&filter[search]=${searchedValue}`;
    }

    if (order_by) {
      const field = USERS_TESTS_SORTING_COLUMN[order_by]
      url += `&order_by=${field}`;
    }

    if (order) {
      url += `&order=${order}`;
    } else {
      url += `&order=desc`;
    }

    return super.get(url, null, null, serialization_group ? serialization_group : "");
  }

  getUserTestForOneUser(limit, page = 1, companyID, isChecked, userID, searchedValue) {
    let url = `api/v1/user-test?limit=${limit}&page=${page}&filter[company]=${companyID}&filter[is_checked]=${isChecked}`;

    if (userID) {
      url += `&filter[user]=${userID}`;
    }

    if (searchedValue) {
      url += `&filter[search]=${searchedValue}`;
    }

    url += "&order=desc";

    return super.get(url);
  }

  getUsersTestFilteredByTeam(
    limit,
    page = 1,
    companyID,
    isChecked,
    teamID,
    searchedValue,
    dateFilter,
    status
  ) {
    let url = `api/v1/user-test?limit=${limit}&page=${page}&filter[company]=${companyID}&filter[is_checked]=${isChecked}`;

    if (teamID) {
      url += `&filter[team]=${teamID}`;
    }

    if (searchedValue) {
      url += `&filter[search]=${searchedValue}`;
    }

    url += "&order=desc";

    if (dateFilter?.length) {
      url += `&filter[available_date_range]=${dateFilter}`;
    }

    if (status) {
      url += `&filter[status]=${status}`;
    }

    return super.get(url);
  }

  createManyTestQuestions(data) {
    return super.post(`api/v1/user-test-question/process-many`, data);
  }

  updateTestQuestion(testQuestionId, data) {
    return super.put(`api/v1/user-test-question/${testQuestionId}`, data);
  }

  getYourCheckedTests(companyID, limit = DEFAULT_DATA_LIMIT) {
    return super.get(
      `api/v1/user-test?limit=${limit}&page=1&filter[company]=${companyID}&filter[your_user_tests]=${1}&filter[is_checked]=${1}&order=desc&filter[return_total_questions_in_lesson]=true`
    );
  }

  getYourTestsFilteredByCourse(companyID, courseID, limit = BIGGER_DATA_LIMIT) {
    return super.get(
      `api/v1/user-test?limit=${limit}&page=1&filter[company]=${companyID}&filter[your_user_tests]=${1}&filter[course]=${courseID}`
    );
  }

  getUserTestsFilteredByCourse(companyID, courseID, userID, limit = BIGGER_DATA_LIMIT) {
    return super.get(
      `api/v1/user-test?limit=${limit}&page=1&filter[company]=${companyID}&filter[user]=${userID}&filter[course]=${courseID}` 
    );
  }

  getUserTestsFilteredByLesson(companyID, lessonID, userID, limit = BIGGER_DATA_LIMIT) {
    let url = `api/v1/user-test?limit=${limit}&page=1&filter[lesson]=${lessonID}`;

    if (companyID) {
      url += `&filter[company]=${companyID}`;
    }

    if (userID) {
      url += `&filter[user]=${userID}`;
    } else {
      url += `&filter[your_user_tests]=${1}`;
    }

    return super.get(url);
  }

  getOneTest(testID, serialization_group) {
    return super.get(`api/v1/user-test/${testID}`, null, null, serialization_group);
  }

  getAnswers() {
    return super.get("api/v1/answer");
  }

  getPresignedUploadUrls(data) {
    return super.post("api/v1/comment-test/get-aws-s3-presigned-upload-urls", data);
  }

  createUsersTest(data) {
    return super.post("api/v1/user-test", data);
  }

  createTestComment(data) {
    return super.post("api/v1/comment-test", data);
  }

  updateTestComment(commentID, data) {
    return super.put(`api/v1/comment-test/${commentID}`, data);
  }

  addCommentFiles(commentID, data) {
    return super.post(`api/v1/comment-test/${commentID}/add-many-comment-test-filenames`, data);
  }

  getUsersTestQuestions() {
    return super.get("api/v1/user-test-question");
  }

  createUsersTestQuestion() {
    return super.post("api/v1/user-test-question");
  }

  updateTest(testId, data) {
    return super.put(`api/v1/user-test/${testId}`, data);
  }
}
