import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import FiltersBlock from "./FiltersBlock/FiltersBlock";
import CourseBlock from "../../components/CourseBlock/CourseBlock";
import useHandleModal from "../../hooks/dom/useHandleModal";
import CourseApi from "../../utils/api/CourseApi";
import { isMobileDevice } from "../../utils/usersHelper";
import arrow from "../../assets/images/arrow_right.svg";
import pausedIcon from "../../assets/images/symbols/pause.svg";
import Loader from "../../components/Loader/Loader";

import { useSelector } from "react-redux";

import { ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from "../../constants/user";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import useUserRole from "../../hooks/useUserRole";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useFindTranlsations from "../../hooks/useFindTranlsations";
import { useNavigate, useParams } from "react-router-dom";
import { DEFAULT_TEXT } from "../../constants/languages";
import useChangeCourse from "../../hooks/useChangeCourse";

import styles from "./styles.module.scss";
import cn from "classnames";

const CoursesStudentFilterPage = () => {
   const navigate = useNavigate();
   const params = useParams();

   const userId = useSelector((state) => state?.user?.info?.id);
   const t = useFindTranlsations();
   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId);
   const [allTags, setAllTags] = useState([]);

   const isUserAsStudent = useSelector(
      (state) => state.sidebar.isUserAsStudent
   );
   const isUserAsStudentAndNotSuperAdmin =
      userRoleId === ROLE_ID_SUPER_ADMIN ? false : isUserAsStudent;

   const isExcludeCoursesWhereAdminAsStudent =
      userRoleId !== ROLE_ID_SUPER_ADMIN && userRoleId !== ROLE_ID_STUDENT;

   const {
      courseList,
      isCoursesLoading,
      refetchCourses,
      totalCourses,
      setCurrentPage,
      currentPage,
      activeCourseTagsFilter,
      setActiveCourseTagsFilter,
      studentCourseFilter,
      setStudentCourseFilter,
      searchedValue,
      setSearchedValue,
      clearExtendedFilters,
   } = useGetYourCoursesInCompany(
      currentCompanyId,
      isUserAsStudentAndNotSuperAdmin,
      null,
      null,
      isExcludeCoursesWhereAdminAsStudent
   );

   const {
      isChangeLoading,
      onRetakeCourse,
      retakeModal,
      onRetakeModalOpen,
      onConfirmPausedOpen,
      onRedirectClickedCourse,
      confirmPausedModal,
      onConfirmOpen,
   } = useChangeCourse(refetchCourses, userId);

   const getAllTags = async () => {
     const res = await new CourseApi().getAllTags();
     if (res?.success?.data) {
       setAllTags(res?.success?.data);
     }
   };

   useEffect(() => {
      getAllTags();
    }, []);

   useEffect(() => {
      if (params?.filterId === "0") {
         setStudentCourseFilter("ongoing");
      }

      if (params?.filterId === "1") {
         setStudentCourseFilter("completed");
      }

      if (params?.filterId === "2") {
         setStudentCourseFilter("failed");
      }
   }, [params]);

   const lastCourseRef = useIntersectionObserver(() => {
      if (!isCoursesLoading && courseList?.length < totalCourses) {
         if (courseList?.length === 0) {
            setCurrentPage(1);
         } else {
            setCurrentPage((prev) => prev + 1);
         }
      }
   }, [isCoursesLoading, totalCourses]);

   const pageTitle =
      studentCourseFilter === "ongoing"
         ? t.returnTranslation("Ongoing")
         : studentCourseFilter === "failed"
           ? t.returnTranslation("Failed")
           : studentCourseFilter === "completed"
             ? t.returnTranslation("Completed")
             : t.returnTranslation("Ongoing");

   return (
      <MainLayout>
         <div className={styles.coursesPage}>
            <div className={styles.filters_page_title}>{pageTitle}</div>
            <div className={styles.filter_wrapper}>
               <FiltersBlock
                  key={"courses_filters"}
                  studentCourseFilter={studentCourseFilter}
                  setStudentCourseFilter={setStudentCourseFilter}
                  searchWhat={
                     DEFAULT_TEXT?.courses_and_folders?.searchCourseNameTags
                  }
                  isCoursesOnCoursesPage
                  combinedFilters
                  clearExtendedFilters={clearExtendedFilters}
                  isCoursesFiltersPage
                  currentTags={allTags}
                  activeTagsFilter={activeCourseTagsFilter}
                  setActiveTagsFilter={setActiveCourseTagsFilter}
                  paginatedSearchedValue={searchedValue}
                  setPaginatedSearchedValue={setSearchedValue} 
               />
            </div>

            <div className={styles.list}>
               {isCoursesLoading && courseList?.length === 0 && (
                  <div className={cn(styles.w_full, styles.w_full_courses)}>
                     {Array(4)
                        .fill(null)
                        .map((item, idx) => (
                           <div
                              key={`course_template_first${idx}`}
                              className={styles.sceletonCourse_wrapper}
                           >
                              <div className={cn(styles.sceletonCourse)}></div>
                              <div className={styles.loadingTitleCourse}></div>
                           </div>
                        ))}
                  </div>
               )}
               {courseList?.map((item, key) => (
                  <CourseBlock
                     maxWidth={"100%"}
                     lastCourseRef={
                        courseList?.length - 1 === key ? lastCourseRef : null
                     }
                     refetchCourses={refetchCourses}
                     key={`course_${item?.id}`}
                     studentCourseFilter={studentCourseFilter}
                     course={item}
                     onRetakeModalOpen={onRetakeModalOpen}
                     onConfirmPausedOpen={onConfirmPausedOpen}
                     onConfirmOpen={onConfirmOpen}
                     isLoading={isCoursesLoading}
                  />
               ))}
            </div>

            {isCoursesLoading && courseList?.length > 0 && (
               <div
                  className={cn(
                     "default_loader_wrapper",
                     currentPage > 1 ? styles.loader_more_wrapper : ""
                  )}
               >
                  <Loader size={"small"} />
               </div>
            )}

            {courseList?.length === 0 && !isCoursesLoading && (
               <div>
                  <EmptyBlock />
               </div>
            )}
         </div>

         <ConfirmModal
            confirmButtonText={t?.retakeCourse}
            onConfirm={onRetakeCourse}
            onClose={retakeModal.close}
            isOpen={retakeModal.isActive}
            title={t?.areYouSure}
            maxHeight={isMobileDevice() ? "270px" : "300px"}
         />

         <ConfirmModal
            icon={pausedIcon}
            confirmButtonText={t?.returnTranslation("Open course")}
            onConfirm={onRedirectClickedCourse}
            onClose={confirmPausedModal.close}
            isOpen={confirmPausedModal.isActive}
            title={t?.courseWasPaused}
            subtitle={t?.returnTranslation(
               "You cannot continue learning, but completed lessons and finished tests are available. If there is a deadline, it will be suspended"
            )}
         />
      </MainLayout>
   );
};

export default CoursesStudentFilterPage;
