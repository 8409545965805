import React, { useState, useRef, useEffect } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import SubmenuBlock from "./SubmenuBlock/SubmenuBlock";
import TinymceBlock from "./TinymceBlock/TinymceBlock";
import QuestionsBlock from "./QuestionsBlock/QuestionsBlock";
import SelectPositionBlock from "./QuestionItem/QuestionItem";
import { LESSON_TYPE_WITHOUT_TESTING_NUMBER, LESSON_TYPE_WITH_TESTING_NUMBER, QUESTION_TYPES_DATA } from "../../constants/courses";
import Draggable from "react-draggable";
import { useParams } from "react-router-dom";
import CourseApi from "../../utils/api/CourseApi";
import ModuleApi from "../../utils/api/ModuleApi";
import LessonApi from "../../utils/api/LessonApi";
import UserApi from "../../utils/api/UserApi";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import { COMPARE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, defineQuestionDefaultObj } from "../../utils/questionsHelper";
import { toast } from "react-toastify";
import { decodeSanitizedContent, decodeSanitizedInputValue, getRandomLetter, replaceIframesWithLiteYouTube, sortByDisplaySequence, sortBySequence } from "../../utils/coursesHelper";
import plus from "../../assets/images/symbols/plus.svg";
import NoEntityAdded from "../../components/NoEntityAdded/NoEntityAdded";
import { useSelector } from "react-redux";

import cn from 'classnames';
import styles from "./styles.module.scss";
import UserAnswerApi from "../../utils/api/UserAnswerApi";
import KnowledgeCheckAPI from "../../utils/api/KnowledgeCheckAPI";
import useHandleModal from "../../hooks/dom/useHandleModal";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import { PART_OF_MENTOR_NAME, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from "../../constants/user";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import BecomeCuratorModal from "../../components/Modals/BecomeCuratorModal/BecomeCuratorModal";
import { useLocation } from 'react-router-dom'
import useUserRole from "../../hooks/useUserRole";
import { QUESTIONS_IN_LESSON_GROUP } from "../../utils/api/serialization_groups";
import { useDispatch } from 'react-redux';
import { setConfirmModalNeeded, setCuratorModalHash } from "../../store/actions/sidebar";
import { COURSE_LANGUAGES_LIST } from "../../constants/languages";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const CourseCreationPage = ({ modal }) => {
   const params = useParams();
   const location = useLocation();
   const droppedBlockRef = useRef(null);

   const confirmModal = useHandleModal();
   const becomeCuratorModal = useHandleModal();
   const changeLanguageModal = useHandleModal();

   const t = useFindTranslationsByString();

   const dispatch = useDispatch();

   const [course, setCourse] = useState([]);
   const [defaultCourse, setDefaultCourse] = useState([]);
   const [questions, setQuestions] = useState([]);
   const [defaultQuestions, setDefaultQuestions] = useState([]);
   const [questionsLoading, setQuestionsLoading] = useState(true);
   const [isLessonsSidebarOpen, setIsLessonsSidebarOpen] = useState(true);
   const [assignCuratorLoading, setAssignCuratorLoading] = useState(false);
   const [changedLessonFlag, setChangedLessonFlag] = useState('still');
   const [isSaved, setIsSaved] = useState(false);
   const [courseLoading, setCourseLoading] = useState(true);

   const [defaultCourseLanguage, setDefaultCourseLanguage] = useState(params?.defaultLang || 'en_US');
   
   const [editLanguage, setEditLanguage] = useState(defaultCourseLanguage);
   const [courseTranlatedLocales, setCourseTranlatedLocales] = useState([]);

   // if question tittle, or anser title or answers are not chosen at all its becoming true
   const [isFieldEmpty, setIsFieldEmpty] = useState(false);

   // isVersionsOpen is for lesson with text editor
   const [isVersionsOpen, setIsVersionsOpen] = useState(false);
   const [lastDropped, setLastDropped] = useState(null);
   const [insideQuestionBlock, setInsideQuestionBlock] = useState(false);

   const [modules, setModules] = useState([]);
   const [defaultModules, setDefaultModules] = useState([]);
   const [modulesLoading, setModulesLoading] = useState(true);
   const [isTestChanged, setIsTestChanged] = useState(false);
   const [amountOfTestsAndLessons, setAmountOfTestsAndLessons] = useState(0);

   const [activeModule, setActiveModule] = useState(0);
   const [activeElement, setActiveElement] = useState(null);
   const [wantedActiveElement, setWantedActiveElement] = useState(null);
   const [draggedIndex, setDraggedIndex] = useState(null);
   const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });

   const [questionsDataFromInputs, setQuestionsDataFromInputs] = useState([]);
   const userDataId = useSelector(state => state?.user?.info?.id);
   const curatorModalHash = useSelector((state) => state.sidebar.curatorModalHash);
   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

   const isUploadingFiles = useSelector(state => state?.sidebar?.confirmModalNeeded);


   const getCourseTranlatedLocales = async () => {
      const res =  await new CourseApi().getAllCourseTranslatedLocales(params?.id);
      if(res?.success?.data) {
         setCourseTranlatedLocales(res?.success?.data);
      }
   }

   const findAndFetchLangData = (lang) => {      
      const checkIfLocaleExist = courseTranlatedLocales?.includes(lang?.code);
      const languageCode = checkIfLocaleExist ? lang?.code : defaultCourseLanguage;

      fetchCourse(false, languageCode);
      fetchModulesData(languageCode);
      fetchData(false, languageCode);
      setQuestionsDataFromInputs([]);
      setEditLanguage(lang);
   }

   const [indentedLangToClick, setIndentedLangToClick] = useState(null);
   

   const changeEditLanguage = async (lang) => {
      if(lang?.id === editLanguage?.id) return;

      if(changedLessonFlag === 'changed') {
         confirmModal.open();
         setIndentedLangToClick(lang);
         return;
      }

      findAndFetchLangData(lang);
   }

   useEffect(()=> {
      if(course?.defaultLanguage && course?.name && defaultCourse?.name && !courseLoading && !modulesLoading) {
         const needModalCondition = defaultCourse?.name === course?.name && editLanguage?.code !== defaultCourse?.defaultLanguage;         

         if(needModalCondition) {
            changeLanguageModal.open();
         } else {
            changeLanguageModal.close();
         }
      }
   }, [defaultCourse, course, courseLoading, modulesLoading])


   useEffect(()=> {
      getCourseTranlatedLocales();
   }, [course, modules])
   
   const handleInputsDataChange = (data, index) => {
      setQuestionsDataFromInputs((prevQuestionsData) => {
       const updatedQuestionsData = [...prevQuestionsData];
   
       if (index >= updatedQuestionsData.length) {
         for (let i = updatedQuestionsData.length; i <= index; i++) {
           updatedQuestionsData.push({});
         }
       }
   
       updatedQuestionsData[index] = data;
       return updatedQuestionsData;
     });
   };

   const onClickModule = (id) => setActiveModule(id);

   const onClickElement = (el) => {
      setIndentedLangToClick(null);
      
      if((changedLessonFlag === 'changed' && el?.id !== activeElement?.id) || isUploadingFiles) {
         confirmModal.open();
         setActiveElement(prev => prev);
         setWantedActiveElement(el);
      } else {
         setActiveElement(el)
      }
   };

   useEffect(()=> {
      if(activeElement?.type === LESSON_TYPE_WITH_TESTING_NUMBER) {
         setChangedLessonFlag('still');
         dispatch(setConfirmModalNeeded(false));
      }
   }, [activeElement])

   const onStartDrag = (key, event, { x, y }) => {
      setDraggedIndex(key);
   };

   const createQuestion = (type) => {
      const questionData = defineQuestionDefaultObj(type, activeElement?.id);
      setInsideQuestionBlock(false);
      setIsFieldEmpty(false);
      setLastDropped(type);
      setQuestions((prevQuestions) => [...prevQuestions, questionData]);
      if (droppedBlockRef.current) {
         const lastQuestion = droppedBlockRef.current.lastElementChild;
         lastQuestion?.scrollIntoView({ behavior: 'smooth', block: 'end' });
       }
   };

   const deleteDroppedQuestion = () => {
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions.pop();
        return updatedQuestions;
      });
    };

    const onDragOverBlock = (type, draggableId) => {
      const blockElement = document.getElementById('question_block');
      
      if (blockElement) {
        const blockRect = blockElement.getBoundingClientRect();
        const blockScrollTop = blockElement.scrollTop;
        
        const draggableElement = document.getElementById(draggableId);
        const draggableRect = draggableElement.getBoundingClientRect();
    
        const isInside =
          draggableRect.left >= blockRect.left &&
          draggableRect.right <= blockRect.right &&
          draggableRect.top >= blockRect.top &&
          draggableRect.bottom <= blockRect.bottom + blockScrollTop;
    
        setInsideQuestionBlock(isInside);
      }
    };
    
   const onStopDrag = (type) => {
      if (insideQuestionBlock) {
         createQuestion(type);
       } 

      setDraggedIndex(null);
      setInitialPosition({ x: 0, y: 0 });
   };

   const fetchCourse = async (firstRequest = false, lang) => {
      if (!params?.id) return;
      setCourseLoading(true);

      const courseApi = new CourseApi();

      const courseResponse = userRoleId === ROLE_ID_SUPER_ADMIN
         ? await courseApi.getCourseByID(params.id, null, lang ? lang :  defaultCourseLanguage)
         : await courseApi.getYourCourseByID(params.id, null, lang ? lang : defaultCourseLanguage);

      if(courseResponse?.success) {
         setCourse(courseResponse?.success?.data);

         const languageData = COURSE_LANGUAGES_LIST?.find(lang => lang?.code === courseResponse?.success?.data?.defaultLanguage);
  
         if(languageData && firstRequest) changeEditLanguage(languageData || defaultCourseLanguage);

         if(!params?.defaultLang) {
            setDefaultCourseLanguage(courseResponse?.success?.data?.defaultLanguage)
         } 
      }
      setCourseLoading(false);
   };

   const fetchDefaultCourse = async () => {
      if (!params?.id) return;
      setCourseLoading(true);

      const courseApi = new CourseApi();

      const courseResponse = userRoleId === ROLE_ID_SUPER_ADMIN
         ? await courseApi.getCourseByID(params.id, null, defaultCourseLanguage)
         : await courseApi.getYourCourseByID(params.id, null, defaultCourseLanguage);

      if(courseResponse?.success) {
         setDefaultCourse(courseResponse?.success?.data);
      }

      setCourseLoading(false);
   };

   const fetchData = async (loading, lang) => {
      if(!loading) setQuestionsLoading(true);

      try {
         const lessonApi = new LessonApi();
    
         const questionsRes = await lessonApi.getAllQuestionsInLesson(activeElement?.id, QUESTIONS_IN_LESSON_GROUP, lang ? lang : defaultCourseLanguage);

         if (questionsRes?.success?.data) {
           const questionsData = questionsRes.success.data;
     
          questionsData.forEach(question => {
            question?.answers?.sort((a, b) => a.sequence - b.sequence);
          });

          const decodedQuestions = questionsData?.map(question => ({
            ...question,
            content: decodeSanitizedInputValue(question?.content),
            description: decodeSanitizedInputValue(question?.description),
            title: decodeSanitizedInputValue(question?.title),
          }))

           setQuestions(decodedQuestions.sort(sortByDisplaySequence));
         } else {
           setQuestions([]);
         }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
         if(!loading) setQuestionsLoading(false);
      }
    };

   const fetchDefaultData = async (loading) => {
      if(!loading) setQuestionsLoading(true);

      try {
         const lessonApi = new LessonApi();
    
         const questionsRes = await lessonApi.getAllQuestionsInLesson(activeElement?.id, QUESTIONS_IN_LESSON_GROUP, defaultCourseLanguage);

         if (questionsRes?.success?.data) {
           const questionsData = questionsRes.success.data;
     
          questionsData.forEach(question => {
            question?.answers?.sort((a, b) => a.sequence - b.sequence);
          });

          const decodedQuestions = questionsData?.map(question => ({
            ...question,
            content: decodeSanitizedInputValue(question?.content),
            description: decodeSanitizedInputValue(question?.description),
            title: decodeSanitizedInputValue(question?.title),
          }))

           setDefaultQuestions(decodedQuestions.sort(sortByDisplaySequence));
         } else {
            setDefaultQuestions([]);
         }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
         if(!loading) setQuestionsLoading(false);
      }
    };

    const returnUpdatedQuestions = async () => {
      const res = await new LessonApi().getAllQuestionsInLesson(activeElement?.id, QUESTIONS_IN_LESSON_GROUP);
      if(res?.success?.data) return res?.success?.data
    }    

    const fetchModulesData = async (lang) => {
      try {
         setModulesLoading(true);
         const moduleApi = new ModuleApi();

         const modulesResponse = userRoleId === ROLE_ID_SUPER_ADMIN
            ? await moduleApi.getModulesInCourse(params.id, null, lang ? lang : defaultCourseLanguage)
            : await moduleApi.getYourModulesInCourse(params.id, null, lang ? lang : defaultCourseLanguage);

         const modulesData = modulesResponse?.success?.data;
         const sortedModulesData = modulesData?.sort(sortBySequence);

         if (modulesResponse?.error) {
            if(modules?.length === 1) setModules([]);
         }

         const modulesWithCount = sortedModulesData?.map((module) => ({
            ...module,
            lessonsCount: module?.lessons?.length,
            lessons: module?.lessons?.sort(sortBySequence)
         }))

         let totalAmountOfLessons = 0;

         modulesWithCount?.forEach((module) => {
            totalAmountOfLessons += module?.lessonsCount
               ? module?.lessonsCount
               : 0;
         });

         let activeLesson = null;

         modulesWithCount?.forEach((module) => {
            if (module?.lessons) {
               module.lessons.forEach((lesson) => {
                  if (lesson.id === activeElement?.id) {
                  activeLesson = lesson;
                  }
               });
            }
         });

         if(activeElement?.id) {
            setActiveElement(activeLesson);
          }

         setAmountOfTestsAndLessons(totalAmountOfLessons);
         setModules(modulesWithCount);
      } catch (error) {
         console.error("Error:", error);
      } finally {
         setModulesLoading(false);
      }
    }

    const fetchDefaultModulesData = async () => {
      try {
         const moduleApi = new ModuleApi();
         const modulesResponse = userRoleId === ROLE_ID_SUPER_ADMIN
            ? await moduleApi.getModulesInCourse(params.id, null, defaultCourseLanguage)
            : await moduleApi.getYourModulesInCourse(params.id, null, defaultCourseLanguage);

         const modulesData = modulesResponse?.success?.data;
         const sortedModulesData = modulesData?.sort(sortBySequence);

         if (modulesResponse?.error) {
            if(modules?.length === 1) setModules([]);
         }

         const modulesWithCount = sortedModulesData?.map((module) => ({
            ...module,
            lessonsCount: module?.lessons?.length,
            lessons: module?.lessons?.sort(sortBySequence)
         }))

         setDefaultModules(modulesWithCount);
      } catch (error) {
         console.error("Error:", error);
      } 
    }
    
    const getQuestionsAndAnswers = async () => {
      await fetchData(true, editLanguage?.code);
    };
    
    const fetchDefaultQuestionsAndAnswers = async () => {
      await fetchDefaultData(true);
   };

    const createModule = async (elementName, elementId) => {
      if (!params?.id) return;

      const moduleData = {
         name: elementName && elementName?.trim()
            ? `${elementName?.trim()}${editLanguage && editLanguage.code !== course.defaultLanguage ? '*' : ''}`
            : `Default name(${getRandomLetter()})`,
         course_id: params.id,
         default_language: defaultCourseLanguage,
         status: 1,
      };

     const res = await new ModuleApi().createModule(moduleData);

      if (res?.success?.data) {
         fetchDefaultModulesData();
         fetchModulesData(editLanguage?.code).then(()=> {
            if(res?.success?.data?.module.id) onClickModule(res?.success?.data?.module.id);
         });
         

         if(editLanguage?.code !== course?.defaultLanguage) {
            const resUpdate = await new ModuleApi().updateModule(res?.success?.data?.module?.id, {
                new_name: elementName?.trim(),
                new_language: editLanguage?.code
             });
         }
      }

      if (res?.error) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }
   
   };

   const toggleLessonsSidebar = () => {
      setIsLessonsSidebarOpen(!isLessonsSidebarOpen);
   }

   const getUserCourseStatuses = async () => {
      const res = await new UserApi().getYourCoursesStatuses({
         course_ids: [course?.id],
         user_ids: [userDataId]
      });

      if(res?.success?.data?.[0]?.isAdminStudent) {
         window.location.replace('/home');
      }
   }

   useEffect(()=> {
      if(course?.id && userDataId) {
         getUserCourseStatuses();
      }
   }, [userDataId, course])

   useEffect(() => {
      if(userRoleId) {
         fetchCourse(true);
         fetchDefaultCourse();
         fetchDefaultModulesData();
      }

      return () => {
         setQuestions([]);
         setQuestionsDataFromInputs([]);
      }
   }, [params?.id, userRoleId]);

   useEffect(() => {
      if (activeElement?.id) {
         getQuestionsAndAnswers();
         fetchDefaultQuestionsAndAnswers();
         setQuestionsDataFromInputs([]);
      }

      return () => {
         setQuestions([]);
         setQuestionsDataFromInputs([]);
      }
   }, [activeElement?.id, editLanguage]);

   const closeCuratorModal = () => {
      becomeCuratorModal.close();
      dispatch(setCuratorModalHash(null))
   }
   
   const assignMyselfAsCurator = async () => {
      setAssignCuratorLoading(true);
      const res = await new CourseApi().editCourseCurators(params?.id, {
         curator_data: [
            {
               user_id: userDataId,
               add: true
            }]
      })

      if(res?.success?.message) {
         toast(<ToastMsg text={'You have been successfully added as a course curator.'} />);
         closeCuratorModal();
      }

      if(res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />)
      }

      setAssignCuratorLoading(false);
   }

   useEffect(()=> {
      if(modal && curatorModalHash === params?.modalHash) becomeCuratorModal.open();
   }, [])

   // Hide the page from students and mentors
   if(userRoleId === ROLE_ID_STUDENT || userRoleName?.includes(PART_OF_MENTOR_NAME)) {
      return <MainLayout><EmptyBlock/></MainLayout> 
   }

   const isNotDefaultLanguage = editLanguage?.id && editLanguage?.code !== defaultCourseLanguage;

   return (
      <MainLayout>
         <div className={styles.courseCreationPage}>
            {!isVersionsOpen && 
               <SubmenuBlock
                  activeElement={activeElement}
                  activeModule={activeModule}
                  onClickElement={onClickElement}
                  onClickModule={onClickModule}
                  course={course}
                  defaultCourse={defaultCourse}
                  questionsDataFromInputs={questionsDataFromInputs}
                  fetchData={fetchModulesData}
                  fetchDefaultModulesData={fetchDefaultModulesData}
                  loading={modulesLoading}
                  amountOfTestsAndLessons={amountOfTestsAndLessons}
                  modules={modules}
                  defaultModules={defaultModules}
                  questions={questions}
                  setModules={setModules}
                  fetchCourse={fetchCourse}
                  createModule={createModule}
                  isLessonsSidebarOpen={isLessonsSidebarOpen}
                  changeEditLanguage={changeEditLanguage}
                  editLanguage={editLanguage}
                  changeLanguageModal={changeLanguageModal}
                  defaultCourseLanguage={defaultCourseLanguage}
                  isNotDefaultLanguage={isNotDefaultLanguage}
               />
            }

            {(!modules || modules?.length === 0) && !modulesLoading && 
               <NoEntityAdded action={()=> createModule('Default name')} entity={'Module'} />
            }

            {activeElement?.type === LESSON_TYPE_WITH_TESTING_NUMBER && (
               <div 
                  id="question_block" 
                  ref={droppedBlockRef}
                  className={cn(
                     styles.questions_block, 
                     !isLessonsSidebarOpen &&  styles.questions_block_wider,
                     isNotDefaultLanguage && styles.full_width
                  )} 
               >
                  <QuestionsBlock
                     questions={questions}
                     setQuestions={setQuestions}
                     handleInputsDataChange={handleInputsDataChange}
                     questionsDataFromInputs={questionsDataFromInputs}
                     setQuestionsDataFromInputs={setQuestionsDataFromInputs}
                     questionsLoading={questionsLoading}
                     lastDropped={lastDropped}
                     activeTest={activeElement}
                     fetchModulesData={fetchModulesData}
                     fetchDefaultModulesData={fetchDefaultModulesData}
                     onClickElement={onClickElement}
                     returnUpdatedQuestions={returnUpdatedQuestions}
                     fetchQuestionsData={fetchData}
                     toggleLessonsSidebar={toggleLessonsSidebar}
                     isLessonsSidebarOpen={isLessonsSidebarOpen}
                     setChangedLessonFlag={setChangedLessonFlag}
                     changedLessonFlag={changedLessonFlag}
                     setIsFieldEmpty={setIsFieldEmpty}
                     isFieldEmpty={isFieldEmpty}
                     editLanguage={editLanguage}
                     defaultCourseLanguage={defaultCourseLanguage}
                     defaultQuestions={defaultQuestions}
                     defaultModules={defaultModules}
                     course={course}
                     isNotDefaultLanguage={isNotDefaultLanguage}
                  />
               </div>
            )}

            <span 
            style={{ '--drop-question': `"${t("Drop new question")}"`}}
            className={cn(
               styles.questions_hover, 
               !isLessonsSidebarOpen ? styles.block_wider : '',
               insideQuestionBlock ? styles.drag_question_inside : '', 
            )}>
            </span>

            {activeElement?.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER && (
               <TinymceBlock 
                  key={activeElement?.id}
                  isLessonsSidebarOpen={isLessonsSidebarOpen}
                  toggleLessonsSidebar={toggleLessonsSidebar}
                  fetchModulesData={fetchModulesData}
                  activeElement={activeElement} 
                  isVersionsOpen={isVersionsOpen}
                  onClickElement={onClickElement}
                  setIsVersionsOpen={setIsVersionsOpen}
                  changedLessonFlag={changedLessonFlag}
                  setChangedLessonFlag={setChangedLessonFlag}
                  setIsSaved={setIsSaved}
                  isSaved={isSaved}
                  editLanguage={editLanguage}
                  defaultCourseLanguage={defaultCourseLanguage}
                  course={course}
               />
            )}

            {activeElement?.type === LESSON_TYPE_WITH_TESTING_NUMBER && !isNotDefaultLanguage && (
               <div className={styles.types_block} id="question_types">
                  <div className={styles.types_header}>
                     <span>{t("Choose the type of question")}</span> 
                  </div>
                  <div className={styles.types}>
                     {QUESTION_TYPES_DATA?.map((item, key) => (
                        <div className={styles.types_wrapper} key={key}>
                           <Draggable
                              onStart={(event, data) =>
                                 onStartDrag(key, event, data)
                              }
                              onDrag={() => onDragOverBlock(item.type, item?.id)}
                              onStop={() => onStopDrag(item.type)}
                              position={initialPosition}
                           >
                              <div
                                 id={item?.id}
                                 className={`${styles.types_item} ${
                                    key === draggedIndex && insideQuestionBlock ? styles.dragging : ""
                                 }`}
                              >
                                 <div className={styles.img_wrapper}>
                                    <img src={item?.image} alt={item?.title} />
                                 </div>
                                 {t(item?.title)}
                              </div>
                           </Draggable>

                           {key === draggedIndex && (
                              <div className={styles.drag_placeholder}>
                                 <span>{t("Drop out")}</span>
                                 <span>{t("to cancel")}</span>
                              </div>
                           )}
                        </div>
                     ))}
                  </div>
                 
               </div>
            )}

            <ConfirmModal
               confirmButtonText={t('Confirm')}
               onConfirm={()=> {
                  setChangedLessonFlag('still');
                  dispatch(setConfirmModalNeeded(false));
                  setActiveElement(wantedActiveElement)
                  confirmModal.close();
                   if(indentedLangToClick)
                      findAndFetchLangData(indentedLangToClick);
               }}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               title={
                  isUploadingFiles ? 
                        t("Are you sure?") :
                        t("Are you sure you wanna leave the lesson. Changes wasn't saved")
               }
               subtitle={ isUploadingFiles ? "The download will stop" : null}
            />

            <BecomeCuratorModal
               confirmButtonText={t('Accept')}
               onConfirm={assignMyselfAsCurator}
               onClose={closeCuratorModal}
               isOpen={becomeCuratorModal.isActive}
               isLoading={assignCuratorLoading}
            />
           
         </div>
      </MainLayout>
   );
};

export default CourseCreationPage;
