import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../../layouts/MainLayout/MainLayout";
import KnowledgeCheckAPI from "../../../utils/api/KnowledgeCheckAPI";
import LessonApi from "../../../utils/api/LessonApi";
import { decodeSanitizedContent, decodeSanitizedInputValue, replaceIframesWithLiteYouTube, returnUpdatedContent, sortByDisplaySequence, sortBySequence } from "../../../utils/coursesHelper";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import { ANSWER_STATUS_CHOSEN_AND_RIGHT, ANSWER_STATUS_CHOSEN_AND_WRONG, ANSWER_STATUS_NOT_CHOSEN_AND_RIGHT, ANSWER_STATUS_NOT_CHOSEN_AND_WRONG, QUESTION_IMAGES, QUESTION_NAMES, TEST_STATUS_APPROVED, TEST_STATUS_ON_REWORK, TEST_STATUS_REJECTED, testQuestionsList } from "../../../constants/tests";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import AnswersInfoModal from "../../../components/Modals/AnswersInfoModal/AnswersInfoModal";

import LeftPanel from "./LeftPanel/LeftPanel";
import CenterBlock from "./CenterBlock/CenterBlock";

import cn from "classnames";
import styles from "./styles.module.scss";
import { LESSON_TYPE_WITHOUT_TESTING_NUMBER, MAX_VIDEO_SIZE } from "../../../constants/courses";
import axios from "axios";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { COMPARE_ANSWER_CODE, QUESTIONS_THAT_NEED_EXTRA_CREATION, QUESTIONS_THAT_NEED_EXTRA_CREATION_PLUS_COMPARE, QUESTIONS_THAT_NOT_INCLUDED_IN_AUTO_CALCULATION, RATE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, STARS_ANSWER_CODE } from "../../../utils/questionsHelper";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { TEST_EXTRA_GROUP_ONE } from "../../../utils/api/serialization_groups";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const initialChartData = [
   ["Correct status", "percentage"],
   ["Correct", 0],
   ["Wrong", 5],
   ["Partially correct", 15],
];

const ActualKnowledgeCheckStudent = () => {
   const [isTheory, setIsTheory] = useState(false);
   const [dataLoading, setDataLoading] = useState(true);
   const [chartData, setChartData] = useState(initialChartData);
   const [fileName, setFileName] = useState('');
   const [testData, setTestData] = useState({});
   const [questions, setQuestions] = useState([]);

   const t = useFindTranslationsByString();

   const userId = useSelector((state) => state?.user?.info?.id);

   const params = useParams();

   const [lessonContent, setLessonContent] = useState("");
   const [lessonName, setLessonName] = useState("");

   const answersInfoModal = useHandleModal();

   const openAnswersInfoModal = () => answersInfoModal.open();
   
   const getTest = async () => {
      setDataLoading(true);
      const res = await new KnowledgeCheckAPI().getOneTest(params?.id, TEST_EXTRA_GROUP_ONE);

      if(res?.success?.data) {
         const test = res?.success?.data;
         const questions = test?.lesson?.questions;
         // we revese it to find the latest test question.
         const userTestQuestions = [...test?.userTestQuestions].reverse();
         
         setTestData(test); 

         const mapSequence = (ans, ansIdx, question) => {
            const isCorrect = ans?.answer?.content === question?.sequence?.[ansIdx];
               let status = 0;

               if (isCorrect) {
                  status = ANSWER_STATUS_CHOSEN_AND_RIGHT;
               } else {
                  status = ANSWER_STATUS_CHOSEN_AND_WRONG;
               }

            return  {
               ...ans, 
               content: decodeSanitizedInputValue(ans?.answer.content),
               status
            }
         }

         const mapMostAnswers = (question, answer, answerIdx) => {
            const isAnswerChosen = userTestQuestions?.find(testQ => testQ.question?.id === question?.id)?.userAnswers?.some(userAnswer => userAnswer.answer?.id === answer?.id);

            if (question?.type === COMPARE_ANSWER_CODE) {
               const userTestQuestion = userTestQuestions?.find(testQ => testQ.question?.id === question?.id);
               const userAnswer = userTestQuestion?.userAnswers?.find(userAnswer => userAnswer.answer?.id === answer?.id);

               answer.isCorrect = userAnswer?.content === answer?.content;
             }

            const isCorrect = answer?.isCorrect;
        
            let status = 0; // Default status (chosen and right)
        
            if (isAnswerChosen) {
               if (isCorrect) {
                 status = ANSWER_STATUS_CHOSEN_AND_RIGHT;
               } else {
                 status = ANSWER_STATUS_CHOSEN_AND_WRONG;
               }
             } else if (!isAnswerChosen && isCorrect) {
               status = ANSWER_STATUS_NOT_CHOSEN_AND_RIGHT;
             } else if (!isAnswerChosen && !isCorrect) {
               status = ANSWER_STATUS_NOT_CHOSEN_AND_WRONG;
             }
        
            return {
              ...answer,
              content: 
                   QUESTIONS_THAT_NEED_EXTRA_CREATION_PLUS_COMPARE.includes(question?.type)
                     ?
                        (userTestQuestions?.find(testQ => testQ?.question?.id === question?.id)?.userAnswers
                        ?.find(userAnswer => userAnswer?.answer?.id === answer?.id)?.content || "")
                     : answer?.content,
              initialContent: answer?.content,
              status: status,
            };
         }

         setQuestions(test?.lesson?.questions?.map((question, idx) => ({
                  ...question,
                  name: QUESTION_NAMES[question?.type],
                  image: QUESTION_IMAGES[question?.type],
                  result: userTestQuestions?.find(testQ => testQ.question?.id === question?.id)?.successPercentage || 0,
                  ...(question?.type === COMPARE_ANSWER_CODE 
                        ? {answers: question?.answers.sort((a, b) => a?.matchingOption - b?.matchingOption)?.map((answer, answerIdx)=> mapMostAnswers(question, answer, answerIdx))} 
                        : {answers: question?.answers.map((answer, answerIdx)=> mapMostAnswers(question, answer, answerIdx))?.sort(sortBySequence)}
                     ),
                  ...(question?.type === SEQUENCE_ANSWER_CODE 
                        ? 
                        {sequenceAnswers: (userTestQuestions?.find(testQ => testQ.question?.id === question?.id)?.userAnswers || [])?.map((ans, ansIdx) => mapSequence(ans, ansIdx, question))} 
                        : {}),
                  status: question?.type === RATE_ANSWER_CODE || question?.type === STARS_ANSWER_CODE ? ANSWER_STATUS_CHOSEN_AND_RIGHT : userTestQuestions?.find(testQ => testQ.question?.id === question?.id)?.status || 0
               }))
               .map(question => ({
                  ...question,
                  content: decodeSanitizedInputValue(question?.content),
                  description: decodeSanitizedInputValue(question?.description),
                  title: decodeSanitizedInputValue(question?.title),
                }))
            )
      }
      setDataLoading(false);
   };

   const calculatePercentage = (count, total) => ((count / total) * 100);

   const updateChartData = () => {
      const filteredQuestions = questions.filter(question => question.type !== STARS_ANSWER_CODE && question.type !== RATE_ANSWER_CODE && [0,1,2,3].includes(question?.status));
      const totalQuestionsCount = filteredQuestions.length;

      const resultCounts = questions.reduce(
         (accumulator, question) => {
           const result = question.status;
           const questionType = question.type;
       
           if (questionType !== STARS_ANSWER_CODE && questionType !== RATE_ANSWER_CODE && [0,1,2,3].includes(question?.status)) {
             if (result === TEST_STATUS_REJECTED) {
               accumulator.wrong++;
             } else if (result === TEST_STATUS_APPROVED) {
               accumulator.correct++;
             } else if (result === TEST_STATUS_ON_REWORK) {
               accumulator.partially_correct++;
             }
           }
       
           return accumulator;
         },
         { correct: 0, wrong: 0, partially_correct: 0 }
       );

      const newData = [
         ["Correct status", "percentage"],
         ["Correct", Math.round(calculatePercentage(resultCounts.correct, totalQuestionsCount))],
         ["Wrong", calculatePercentage(resultCounts.wrong, totalQuestionsCount)],
         ["Partially correct", calculatePercentage(resultCounts.partially_correct, totalQuestionsCount)],
      ];

      setChartData(newData);
   };

   const addManualResult = (questionId, resultStatus) => {
      setQuestions(prevQuestions => {
        return prevQuestions.map(question => {
          if (question.id === questionId) {
              question.status = resultStatus;
          }

          return question;
        });
      });
    };

   useEffect(() => {
      updateChartData();
   }, [questions]);

   useEffect(() => {
      if(questions) {
         const isOnlyAuto = questions.every(q => !QUESTIONS_THAT_NOT_INCLUDED_IN_AUTO_CALCULATION.includes(q.type))

         if(testData?.successPercentage && isOnlyAuto) {
            const newData = [
               ["Correct status", "percentage"],
               ["Correct", Math.round(testData?.successPercentage)],
               ["Wrong", 100 - testData?.successPercentage],
               ["Partially correct", 0],
            ];
      
            setChartData(newData);
         }
      }

   }, [questions]);

   useEffect(() => {
      if(params?.id) {
         getTest();
      } 
   }, [params]);

   const getOneLesson = async (id) => {
      const res = await new LessonApi().getOneLesson(id);
        if (res?.success?.data) {
           const decodedContent = decodeSanitizedContent(res?.success?.data?.content);
           const replacedWithLite = replaceIframesWithLiteYouTube(decodedContent || t('There are no data'));

           const content = await returnUpdatedContent(replacedWithLite, userId, testData?.lesson?.module?.course?.id, true);

           setLessonContent(content || replacedWithLite);
           setLessonName(res?.success?.data?.name);
        }
    }

   const getLessonsInModule = async (moduleId) => {
      const res = await new LessonApi().getLessonsInModule(moduleId);
      if(res?.success?.data) {
         const sortedLessons = res?.success?.data?.sort(sortBySequence);
         const currentIndex = sortedLessons.findIndex(
            (lesson) => lesson.id === testData?.lesson?.id
          );

          const previousLesson = sortedLessons
            .slice(0, currentIndex)
            .reverse()
            .find((lesson) => lesson.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER);

        if(previousLesson?.id) {
            getOneLesson(previousLesson?.id);
        } else {
            setLessonContent(t('There are no data'));
        }
      }
   }


   useEffect(() => {
      if(testData?.lesson?.module?.id && userId) {
         getLessonsInModule(testData?.lesson?.module?.id)
      }
   }, [testData, userId]);

   return (
      <MainLayout>
         <div className={styles.knowledge_check_page}>
            <LeftPanel 
               chartData={chartData}     
               isTheory={isTheory}
               setIsTheory={setIsTheory}
               testData={testData}
            />
 
            <CenterBlock
               testData={testData}
               lessonName={lessonName}
               isTheory={isTheory}
               chartData={chartData}
               lessonContent={lessonContent}
               testQuestionsList={questions}
               openAnswersInfoModal={openAnswersInfoModal}
               commentInputValue={testData?.commentTests?.[0]?.comment || t('No comments!')}
               fileName={fileName}
               addManualResult={addManualResult}
               dataLoading={dataLoading}
            />

            <AnswersInfoModal
               title={t("Types of answers")}
               onClose={answersInfoModal.close}
               isOpen={answersInfoModal.isActive}
            />
         </div>
      </MainLayout>
   );
};

export default ActualKnowledgeCheckStudent;
