import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import Input from "../Input/Input";
import arrow from "../../assets/images/symbols/arrow.svg";
import crossImg from "../../assets/images/symbols/cross_grey.svg";
import arrowWhite from "../../assets/images/symbols/arrow_white.svg";
import searchIcon from "../../assets/images/symbols/Search.svg";
import cn from "classnames";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import Checkbox from "../Checkbox/Checkbox";
import notFound from "../../assets/images/symbols/nothing_found.svg";
import useFindTranslations from "../../hooks/useFindTranlsations";

const DropdownWithSearch = ({
  data,
  placeholder,
  onChange,
  value,
  isWhiteBlack = false,
  searchNeeded,
  isError,
  lastItemRef,
  searchValue,
  setSearchValue,
  selectedItems,
  setSelectedItems,
  leftIcon,
  onClear,
    inputClassnames,
  isMulti = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const inputRef = useRef();
  const inputRefTop = useRef();

  const t = useFindTranslations();

  const onClose = () => {
    setIsOpen(false);
    setSearchValue && setSearchValue("");
  };
  const onToggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  const onChangeValue = (val) => {
    onChange(val);
    if (!isMulti) {
      setIsOpen(false);
    }
  };

  useOutsideClick(dropdownRef, onClose, inputRef);

  useEffect(() => {
    if (isOpen) inputRefTop.current.focus();
  }, [isOpen]);

  const firstSelectedItems = isMulti && selectedItems?.slice(0, 3);
  let croppedPlaceholder = "";

  if (Array.isArray(firstSelectedItems) && firstSelectedItems?.length > 0) {
    const names = firstSelectedItems?.map((item) => item?.name);
    croppedPlaceholder = names.join(", ");
    if (croppedPlaceholder?.length > 20) {
      croppedPlaceholder = croppedPlaceholder?.substring(0, 20) + "...";
    }
  }

  return (
    <>
      <div
        className={cn(
          styles.dropdown_wrapper,
          value?.length && searchNeeded ? styles.value : "",
        )}
        onClick={onToggle}
        ref={inputRef}
      >
        {searchNeeded && isOpen && (
          <img className={styles.search} src={searchIcon} alt="" />
        )}
        {leftIcon && (
          <img
            className={cn(
              styles.search,
              "left_icon",
              selectedItems?.length && styles.icon_light,
            )}
            src={leftIcon}
            alt=""
          />
        )}
        <Input
          placeholder={croppedPlaceholder || placeholder}
          ref={inputRefTop}
          customClass={cn(
            styles.input,
            !isOpen && !value?.name && searchNeeded ? styles.pl_0 : "",
            isOpen && styles.open,
            (croppedPlaceholder || selectedItems?.length || value?.length) &&
              styles.placeholder_white,
              inputClassnames
          )}
          disabled={!isOpen || !searchNeeded}
          value={searchValue}
          onChangeValue={(e) => {
            setSearchValue && setSearchValue(e?.target?.value);
          }}
          isWhiteBlack={isWhiteBlack}
          isError={isError ? styles.error : ""}
        />

        <div className={styles.click_el}></div>

        {!!(selectedItems?.length || value?.length) ? (
          <img
            onClick={(e) => {
              e.stopPropagation();
              isMulti && setSelectedItems && setSelectedItems([]);
              onClear && onClear();
            }}
            className={cn(styles.arrow, styles.icon_light, "right_icon")}
            src={crossImg}
            alt=""
          />
        ) : (
          <img
            src={isWhiteBlack ? arrowWhite : arrow}
            alt={""}
            className={cn(styles.arrow, isOpen && styles.arrow_open, "right_icon")}
          />
        )}

        {isOpen && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={cn(styles.dropdown)}
          >
            {!!data?.length ? (
              <div className={styles.list}>
                {data?.map((item, key) => (
                  <div
                    ref={data?.length - 1 === key ? lastItemRef : null}
                    key={key}
                    onClick={(e) => {
                      e.stopPropagation();
                      onChangeValue(item);
                    }}
                    className={styles.list_item}
                  >
                    {isMulti && (
                      <Checkbox
                        hiddenTitle={true}
                        isChecked={selectedItems?.some(
                          (sItem) => sItem?.id === item?.id,
                        )}
                        className={styles.checkbox}
                        inputClassName={styles.inputCheckbox}
                      />
                    )}

                    <div className={styles.info}>
                      <CustomTooltip
                        text={item?.name || item}
                        id={item?.id || key}
                        limit={15}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.notFound}>
                <img src={notFound} alt="" />
                {t?.thereAreNoData}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownWithSearch;
