import { ReactComponent as TempFolderIcon1 } from "../assets/images/folder_icons/icons_for_icon_picker-1.svg";
import { ReactComponent as TempFolderIcon2 } from "../assets/images/folder_icons/icons_for_icon_picker-2.svg";
import { ReactComponent as TempFolderIcon3 } from "../assets/images/folder_icons/icons_for_icon_picker-3.svg";
import { ReactComponent as TempFolderIcon4 } from "../assets/images/folder_icons/icons_for_icon_picker-4.svg";
import { ReactComponent as TempFolderIcon5 } from "../assets/images/folder_icons/icons_for_icon_picker-5.svg";
import { ReactComponent as TempFolderIcon6 } from "../assets/images/folder_icons/icons_for_icon_picker-6.svg";
import { ReactComponent as TempFolderIcon7 } from "../assets/images/folder_icons/icons_for_icon_picker-7.svg";
import { ReactComponent as TempFolderIcon8 } from "../assets/images/folder_icons/icons_for_icon_picker-8.svg";
import { ReactComponent as TempFolderIcon9 } from "../assets/images/folder_icons/icons_for_icon_picker-9.svg";
import { ReactComponent as TempFolderIcon10 } from "../assets/images/folder_icons/icons_for_icon_picker-10.svg";
import { ReactComponent as TempFolderIcon11 } from "../assets/images/folder_icons/icons_for_icon_picker-11.svg";
import { ReactComponent as TempFolderIcon12 } from "../assets/images/folder_icons/icons_for_icon_picker-12.svg";
import { ReactComponent as TempFolderIcon13 } from "../assets/images/folder_icons/icons_for_icon_picker-13.svg";
import { ReactComponent as TempFolderIcon14 } from "../assets/images/folder_icons/icons_for_icon_picker-14.svg";
import { ReactComponent as TempFolderIcon15 } from "../assets/images/folder_icons/icons_for_icon_picker-15.svg";
import { ReactComponent as TempFolderIcon16 } from "../assets/images/folder_icons/icons_for_icon_picker-16.svg";
import { ReactComponent as TempFolderIcon17 } from "../assets/images/folder_icons/icons_for_icon_picker-17.svg";
import { ReactComponent as TempFolderIcon18 } from "../assets/images/folder_icons/icons_for_icon_picker-18.svg";
import { ReactComponent as TempFolderIcon19 } from "../assets/images/folder_icons/icons_for_icon_picker-19.svg";
import { ReactComponent as TempFolderIcon20 } from "../assets/images/folder_icons/icons_for_icon_picker-20.svg";

import password from "../assets/images/symbols/password.svg";
import settingsImg from "../assets/images/symbols/setting.svg";
import settingsImgWhite from '../assets/images/symbols/setting_white.svg'
import passwordWhite from '../assets/images/symbols/password_white.svg'
import book_not_filled from "../assets/images/folder_icons/book_not_filled.svg";
import book_active from "../assets/images/courses-fill.svg";

const commonIconName = "icons_for_icon_picker";

export const FOLDER_ICONS_LIST = [
   { id: 20, name: `${commonIconName}-20`, Icon: TempFolderIcon20 },
   { id: 19, name: `${commonIconName}-19`, Icon: TempFolderIcon19 },
   { id: 1, name: `${commonIconName}-1`, Icon: TempFolderIcon1 },
   { id: 2, name: `${commonIconName}-2`, Icon: TempFolderIcon2 },
   { id: 3, name: `${commonIconName}-3`, Icon: TempFolderIcon3 },
   { id: 4, name: `${commonIconName}-4`, Icon: TempFolderIcon4 },
   { id: 5, name: `${commonIconName}-5`, Icon: TempFolderIcon5 },
   { id: 6, name: `${commonIconName}-6`, Icon: TempFolderIcon6 },
   { id: 7, name: `${commonIconName}-7`, Icon: TempFolderIcon7 },
   { id: 8, name: `${commonIconName}-8`, Icon: TempFolderIcon8 },
   { id: 9, name: `${commonIconName}-9`, Icon: TempFolderIcon9 },
   { id: 10, name: `${commonIconName}-10`, Icon: TempFolderIcon10 },
   { id: 11, name: `${commonIconName}-11`, Icon: TempFolderIcon11 },
   { id: 12, name: `${commonIconName}-12`, Icon: TempFolderIcon12 },
   { id: 13, name: `${commonIconName}-13`, Icon: TempFolderIcon13 },
   { id: 14, name: `${commonIconName}-14`, Icon: TempFolderIcon14 },
   { id: 15, name: `${commonIconName}-15`, Icon: TempFolderIcon15 },
   { id: 16, name: `${commonIconName}-16`, Icon: TempFolderIcon16 },
   { id: 17, name: `${commonIconName}-17`, Icon: TempFolderIcon17 },
   { id: 18, name: `${commonIconName}-18`, Icon: TempFolderIcon18 },
];

export const SUBMENU_BASIC_SETTINGS = 1;
export const SUBMENU_COURSES = 2;
export const SUBMENU_ACCESS = 3;

export const FOLDER_SETTINGS_SUBMENU = [
   {
      image: settingsImg,
      activeImage: settingsImgWhite,
      title: "Basic settings",
      titleForTopic: "Basic settings",
      value: SUBMENU_BASIC_SETTINGS,
      text: "Organize and manage information. The changes made will be reflected in the preview",
   },
   {
      image: book_not_filled,
      activeImage: book_active,
      title: "Topics and courses",
      titleForTopic: "Courses",
      value: SUBMENU_COURSES,
      text: "Organize and manage information. The changes made will be reflected in the preview",
   },
   {
      image: password,
      activeImage: passwordWhite,
      title: "Access to the folder",
      titleForTopic: "Access to the topic",
      value: SUBMENU_ACCESS,
      text: "Organize and manage information. The changes made will be reflected in the preview",
   },
];

// Acceess tab on folder setting page for folder or topic
export const USERS_JUST_HEADERS = ["Team", "Name & Surname", "Department", "Position", "Was"];
export const USERS_JUST_FIELDS = [ "team", "name", "department", "position", "was" ]

// Courses or topics tab on folder settings page
export const TOPICS_HEADERS = [
   {title: 'Name', field: 'title'},
   {title: 'Number of courses', field: 'coursesCount'},
   {title: 'Number of users', field: 'readers'},
] 

export const COURSES_INSIDE_FOLDER_HEADERS = [
   {title: 'Name', field: 'name'},
   {title: 'Number of users', field: 'users'},
] 