import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import LeftPanelBlock from "./LeftPanelBlock/LeftPanelBlock";
import Subheader from "./Subheader/Subheader";
import { FOLDER_SETTINGS_SUBMENU, SUBMENU_ACCESS, SUBMENU_BASIC_SETTINGS, SUBMENU_COURSES } from "../../constants/folders";
import BasicSettingsTab from "./BasicSettingsTab/BasicSettingsTab";
import AccessToTheFolderTab from "./AccessToTheFolderTab/AccessToTheFolderTab";
import CourseApi from "../../utils/api/CourseApi";
import FolderApi from "../../utils/api/FolderApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import moment from "moment";
import UserApi from "../../utils/api/UserApi";
import TeamApi from "../../utils/api/TeamApi";
import {
   PART_OF_MENTOR_NAME,
   ROLE_ID_STUDENT,
   ROLE_ID_SUPER_ADMIN,
   USER_STATUS_ACTIVE,
} from "../../constants/user";
import { COURSE_STATUS_ACTIVE } from "../../constants/courses";
import { useSelector } from "react-redux";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import SecondLoader from "../../components/SecondLoader/SecondLoader";
import CoursesAndTopicsTab from "./CoursesAndTopicsTab/CoursesAndTopicsTab";
import { mapUsersWithFilteredRoles, sortRoles } from "../../utils/usersHelper";
import {
   COURSE_SETTINGS_GROUP,
   USER_EXTRA_GROUP_TWO,
} from "../../utils/api/serialization_groups";

import useGetTeams from "../../hooks/api/useGetTeamsOfCompany";
import useGetOneFolder from "../../hooks/api/useGetOneFolder";
import useUserRole from "../../hooks/useUserRole";
import useGetActiveUsersOfCompany from "../../hooks/api/useGetActiveUsersOfCompany";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useGetUsersOfFolder from "../../hooks/api/useGetUsersOfFolder";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useGetTopics from "../../hooks/api/userGetTopics";
import useGetEntetiesForUpdateFolder from "../../hooks/api/useGetEntetiesForUpdateFolder";
import useFindTranslations from "../../hooks/useFindTranlsations";

const FolderSettingsPage = () => {
   const [activeTab, setActiveTab] = useState(SUBMENU_BASIC_SETTINGS);
   const [isUsersLoading, setIsUsersLoading] = useState(false);
   const [courseTeamList, setCourseTeamList] = useState([]);

   const [formData, setFormData] = useState(null);
   const [tableData, setTableData] = useState([]);
   const [selectedTeamFilter, setSelectedTeamFilter] = useState(null);

   const [selectedDepartment, setSelectedDepartment] = useState(null);
   const [selectedPosition, setSelectedPosition] = useState(null);

   const companyId = useSelector((state) => state.sidebar.companyId);
   const userId = useSelector((state) => state?.user?.info?.id);
   const { userRoleId, userRoleName } = useUserRole(companyId);

   const t = useFindTranslations()

   const params = useParams();
   const tagsInputRef = useRef(null);

   //  That means that we are on setting page for folder
   const folderId = params?.topicId || null;

   // We are on setting page for topic 
   const topicId = params?.topicId || null;
   const parentFolderId = params?.folderId || null;

   // Entities for updating access for users
   const updateFolderManager = useGetEntetiesForUpdateFolder(topicId ? topicId : params?.id, companyId);

   const [selectedUserList, setSelectedUserList] = useState([]);
   const [selectedTeamList, setSelectedTeamList] = useState([]);

   const isUserAsStudent = useSelector(
      (state) => state.sidebar.isUserAsStudent
   );

   const isUserAsStudentAndNotSuperAdmin =
   userRoleId === ROLE_ID_SUPER_ADMIN ? false : isUserAsStudent;

   const isExcludeCoursesWhereAdminAsStudent =
   userRoleId !== ROLE_ID_SUPER_ADMIN && userRoleId !== ROLE_ID_STUDENT;

   const { folder: initialFolder, mappedFolder: folder, fetchFolder } = useGetOneFolder(topicId ? topicId : params?.id)

   const {
      setCurrentPage: setCurrentFolderUsersPage,
      userList: folderUserList,
      setUserList: setFolderUserList,
      setSearchedValue: setFolderSearchedValue,
      searchedValue: folderSearchedValue,
      totalUsers: totalFolderUsers,
      isUsersLoading: isFolderUsersLoading,
      refetchUsers: refetchFolderUsers,
   } = useGetUsersOfFolder(params?.topicId ? params?.topicId : params?.id, companyId, selectedTeamFilter?.id, USER_EXTRA_GROUP_TWO);

   const lastFolderUserRef = useIntersectionObserver(() => {
      if (!isFolderUsersLoading && folderUserList?.length < totalFolderUsers) {
         if (folderUserList?.length === 0) {
            setCurrentFolderUsersPage(1);
         } else {
            setCurrentFolderUsersPage((prev) => prev + 1);
         }
      }
   }, [isFolderUsersLoading, totalFolderUsers]);

   const {
      setCurrentPage,
      userList: modalUsersList,
      setUserList: setModalUsersList,
      searchedValue: modalUserSearchedValue,
      totalUsers,
      setSearchedValue: setModalUserSearchedValue,
      isUsersLoading: isModalUsersLoading,
      selectAll: selectAllUsers,
      setSelectAll: setSelectAllUsers,
   } = useGetActiveUsersOfCompany(companyId, false, false, selectedDepartment?.id, selectedPosition?.id);

   const lastModalUserRef = useIntersectionObserver(() => {
      if (!isModalUsersLoading && modalUsersList?.length < totalUsers) {
         if (modalUsersList?.length === 0) {
            setCurrentPage(1);
         } else {
            setCurrentPage((prev) => prev + 1);
         }
      }
   }, [isModalUsersLoading, totalUsers]);

   const teamManager = useGetTeams({ companyId, userRoleName });

   const lastTeamRef = useIntersectionObserver(() => {
      if (
         !teamManager.isTeamsLoading &&
         teamManager.teamsList?.length < teamManager.totalTeams
      ) {
         if (teamManager.teamsList?.length === 0) {
            teamManager.setCurrentPage(1);
         } else {
            teamManager.setCurrentPage((prev) => prev + 1);
         }
      }
   }, [teamManager.isTeamsLoading, teamManager.totalTeams]);

   // All courses in folder!
   const coursesManager = useGetYourCoursesInCompany(
      companyId,
      isUserAsStudentAndNotSuperAdmin,
      topicId ? topicId : params.id,
      null,
      isExcludeCoursesWhereAdminAsStudent
   );

   const lastCourseInFolderRef = useIntersectionObserver(() => {
      if (
         !coursesManager.isCoursesLoading &&
         coursesManager.courseList?.length < coursesManager.totalCourses
      ) {
         if (coursesManager.courseList?.length === 0) {
            coursesManager.setCurrentPage(1);
         } else {
            coursesManager.setCurrentPage((prev) => prev + 1);
         }
      }
   }, [coursesManager.isCoursesLoading, coursesManager.totalCourses]);

   // All topics in folder
   const {
      foldersList,
      isFoldersLoading,
      refetchFolders,
      totalFolders,
      setCurrentPage: setCurrentFolderPage,
      currentPage: currentFolderPage,
      activeFolderNameFilter,
      setActiveFolderNameFilter,
   } = useGetTopics(
      companyId,
      coursesManager.searchedValue,
      coursesManager.activeCourseSortFilter,
      coursesManager.activeCourseFilter,
      topicId ? topicId : params?.id
   );

   const lastFolderRef = useIntersectionObserver(() => {
      if (!isFoldersLoading && foldersList?.length < totalFolders) {
         if (foldersList?.length === 0) {
            setCurrentFolderPage(1);
         } else {
            setCurrentFolderPage((prev) => prev + 1);
         }
      }
   }, [isFoldersLoading, totalFolders]);

   const initFolderFormData = () => {
      if (initialFolder?.id)
         setFormData({
            ...initialFolder,
            new_status: +initialFolder?.status,
            new_name: initialFolder?.name || "",
            new_description: initialFolder?.description || "",
            new_color: initialFolder?.color || "",
            new_icon: initialFolder?.icon || "",
         });
   };

   const refetchData = () => {
      refetchFolderUsers();
      setSelectedUserList([]);
      teamManager.clearAllTeamsFilters();
   };
    
   const onGiveAccess = async () => {
      setIsUsersLoading(true);
      const folderID = params?.topicId ? params?.topicId : params?.id;

      if (selectedUserList.length) {
         const usersData = {
            user_ids: selectedUserList?.map(user => user?.id),
            course_ids: folder?.courses?.map((item) => item?.id),
            status: COURSE_STATUS_ACTIVE,
            notification_for_folder_id: folderID,
            overwrite_user_course_active_or_paused_statuses: false
         }

         const usersVisibilityData = { 
            user_data: selectedUserList?.map(user => ({
                user_id: user?.id,
                add: true
            }))
        };

        if(params?.folderId) {
         //  Access to parent folder
         const usersVisibilityRes = await new FolderApi().changeFolderVisibilityForUsers(params?.folderId, usersVisibilityData)
        }

         const usersVisibilityRes = await new FolderApi().changeFolderVisibilityForUsers(folderID, usersVisibilityData)

         if(folder?.courses?.length) {
            const usersAccessRes = await new CourseApi().addAcceessOfFewCoursesToFewUsers(usersData)
         }
        
         if (usersVisibilityRes?.success?.message) {
            toast(<ToastMsg text={usersVisibilityRes?.success?.message} />);
            updateFolderManager.changeTopicsVisibility(selectedUserList)
         }

         if (usersVisibilityRes?.error?.message) {
            setIsUsersLoading(false);
            return toast(<ToastMsg text={usersVisibilityRes?.error?.message} isError />);
         }
      }

      if (selectedTeamList.length) {
         const teamsData = {
            team_ids: selectedTeamList?.map(user => user?.id),
            course_ids: folder?.courses?.map((item) => item?.id),
            status: COURSE_STATUS_ACTIVE,
            notification_for_folder_id: folderID,
            send_email: false,
            overwrite_user_course_active_or_paused_statuses: false
         }

         const teamsVisibilityData = { 
            folder_ids: [folderID],
            team_ids: selectedTeamList?.map(item => item?.id),
            is_visible: 1
         };

         const resTeams = await new FolderApi().changeFolderVisibilityForTeams(teamsVisibilityData)
         if(folder?.courses?.length) {
            const teamsAccessRes = await new CourseApi().addAcceessOfFewCoursesToFewTeams(teamsData);
         }

         if (resTeams?.success?.message)
            toast(<ToastMsg text={resTeams?.success?.message} />);
            setIsUsersLoading(false);
         if (resTeams?.error?.message)
            return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      setIsUsersLoading(false);
      refetchData();
      fetchFolder();
   };

   const onSave = async () => {
      const settings = {
         ...(formData?.new_name && { new_name: formData.new_name }),
         ...(formData?.new_color && { new_color: `${formData.new_color}` }),
         ...(formData?.new_icon && { new_icon: formData.new_icon }),
         ...(formData?.new_status && { new_status: formData.new_status }),
      };
      
      const res = await new FolderApi().editFolderSetting(
         topicId || params?.id,
         settings
      );

      if (res?.error?.message)
         return toast(<ToastMsg text={res?.error?.message} isError />);

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
      }

      fetchFolder();
   };

   useEffect(() => {
      initFolderFormData();
   }, [initialFolder]);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [activeTab]);

   const changeUserStatus = (userId, status) => {
      // Find the index of the user with the specified userId
      const userIndex = courseUserList.findIndex((user) => user.id === userId);

      if (userIndex !== -1) {
         const updatedUserList = [...courseUserList];
         updatedUserList[userIndex] = {
            ...updatedUserList[userIndex],
            status: status,
         };

         setCourseUserList(updatedUserList);
      }
   };

   const deleteUserFromTable = (userId) => {
      const updatedUserList = courseUserList.filter(
         (user) => user.id !== userId
      );
      setCourseUserList(updatedUserList);
   };

   const mappedFolderUsers = folderUserList?.map((user) => {
      return {
         id: user?.id,
         team: user?.team?.length
            ? user.team.map((item) => item.name).join(", ")
            : "-",
         department: user?.department ? user?.department : "-",
         name:
            user?.firstName || user?.lastName
               ? `${user?.firstName || ""} ${user?.lastName || ""}`
               : "-",
         position: user?.position ? user?.position : "-",
         was: user?.lastActivity
            ? moment(user?.lastActivity).format("DD.MM.YYYY")
            : t?.wasNotOnline,
         progress: user?.progress,
         avatar: user?.avatar || "",
      };
   });

   // Hide the page from students and mentors
   if (
      userRoleId === ROLE_ID_STUDENT ||
      userRoleName?.includes(PART_OF_MENTOR_NAME)
   ) {
      return (
         <MainLayout>
            <EmptyBlock text={t?.noAccess} />
         </MainLayout>
      );
   }

   return (
      <MainLayout>
         <div className={styles.coursesSettingsPage}>
            <LeftPanelBlock
               setActiveTab={setActiveTab}
               activeTab={activeTab}
               folder={folder}
               data={formData}
               setData={setFormData}
               tagsInputRef={tagsInputRef}
               t={t}
            />
            <div className={styles.wrapper}>
               <Subheader
                  title={
                     params?.topicId 
                        ?  
                           FOLDER_SETTINGS_SUBMENU?.find(
                              (item) => item?.value === activeTab
                           )?.titleForTopic
                        :
                           FOLDER_SETTINGS_SUBMENU?.find(
                              (item) => item?.value === activeTab
                           )?.title
                  }
                  text={
                     FOLDER_SETTINGS_SUBMENU?.find(
                        (item) => item?.value === activeTab
                     )?.text
                  }
                  onSave={onSave}
                  onCancel={initFolderFormData}
                  t={t}
               />

               <div className={styles.tab_wrapper}>
                  {activeTab === SUBMENU_BASIC_SETTINGS && (
                     <BasicSettingsTab
                        data={formData}
                        setData={setFormData}
                        course={folder}
                        t={t}
                     />
                  )}

                  {activeTab === SUBMENU_COURSES && (
                     <CoursesAndTopicsTab
                        coursesManager={coursesManager}
                        foldersList={foldersList}
                        lastFolderRef={lastFolderRef}
                        lastCourseInFolderRef={lastCourseInFolderRef}
                        foldersLoading={isFoldersLoading}
                        currentCompanyId={companyId}
                        isUserAsStudentAndNotSuperAdmin={isUserAsStudentAndNotSuperAdmin}
                        isExcludeCoursesWhereAdminAsStudent={isExcludeCoursesWhereAdminAsStudent}
                        refetchFolders={refetchFolders}
                        refetchFolder={fetchFolder}
                        updateFolderManager={updateFolderManager}
                        t={t}
                     />
                  )}

                  {activeTab === SUBMENU_ACCESS && (
                     <AccessToTheFolderTab
                        folderSearchedValue={folderSearchedValue}
                        setFolderSearchedValue={setFolderSearchedValue}
                        selectedUserList={selectedUserList}
                        setSelectedUserList={setSelectedUserList}
                        selectedTeamList={selectedTeamList}
                        setSelectedTeamList={setSelectedTeamList}
                        userList={modalUsersList}
                        lastModalUserRef={lastModalUserRef}
                        modalUserSearchedValue={modalUserSearchedValue}
                        setModalUserSearchedValue={setModalUserSearchedValue}
                        folderUserList={mappedFolderUsers}
                        teamList={teamManager?.teamsList}
                        fetchTeams={teamManager?.refetchTeams}
                        folder={folder}
                        refetchData={()=> {
                           fetchFolder();
                           refetchFolderUsers();
                        }}
                        lastFolderUserRef={lastFolderUserRef}
                        loading={isFolderUsersLoading}
                        isModalUsersLoading={isModalUsersLoading}
                        changeUserStatus={changeUserStatus}
                        deleteUserFromTable={deleteUserFromTable}
                        onGiveAccess={onGiveAccess}
                        companyId={companyId}
                        isUsersLoading={isUsersLoading}
                        selectAllUsers={selectAllUsers}
                        setSelectAllUsers={setSelectAllUsers}
                        selectAllTeams={teamManager?.selectAll}
                        setSelectAllTeams={teamManager?.setSelectAll}
                        modalTeamSearchedValue={teamManager?.searchedValue}
                        setModaTeamrSearchedValue={teamManager.setSearchedValue}
                        lastTeamRef={lastTeamRef}
                        teamsLoading={teamManager?.isTeamsLoading}
                        selectedTeamFilter={selectedTeamFilter}
                        setSelectedTeamFilter={setSelectedTeamFilter}
                        updateFolderManager={updateFolderManager}
                        t={t}
                        selectedDepartment={selectedDepartment}
                        setSelectedDepartment={setSelectedDepartment}
                        selectedPosition={selectedPosition}
                        setSelectedPosition={setSelectedPosition}
                     />
                  )}
               </div>
            </div>
         </div>
      </MainLayout>
   );
};

export default FolderSettingsPage;
