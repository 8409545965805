import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

const Checkbox = ({
  title,
  setIsChecked,
  isChecked,
  onClickCallback = null,
  isRound,
  isGrey,
  withWhiteBorder,
  extraMargin,
  hiddenTitle = false,
  className,
  inputClassName,
}) => {
  const onToggleChecked = () => {
    if (onClickCallback) {
      onClickCallback();
      if (isChecked) return;
    }
    if (setIsChecked) setIsChecked(!isChecked);
  };

  return (
    <div
      className={cn(
        styles.checkbox,
        !hiddenTitle && styles.flex,
        isRound && styles.checkbox_isRound,
        isRound && extraMargin && styles.extraMargin,
        isGrey && styles.checkbox_isGrey,
        withWhiteBorder && styles.checkbox_withWhiteBorder,
        className,
      )}
      onClick={onToggleChecked}
    >
      <div className={styles.checkbox_wrapper}>
        <input
          type="checkbox"
          className={inputClassName}
          checked={isChecked}
          onChange={onToggleChecked}
        />
      </div>
      {!hiddenTitle && title && <span>{title}</span>}
    </div>
  );
};

export default Checkbox;
