import React, { useEffect, useRef, useState } from "react";
import { Chart } from "react-google-charts";
import styles from "./styles.module.scss";
import cn from 'classnames';

const options = {
   pieHole: 0.8,
   is3D: false,
   backgroundColor: "transparent",
   textStyle: {
      color: "#fff",
   },
   colors: ["#19B256", "#F24B4B", "#FADC3E"],
   pieSliceBorderColor: "transparent",
   legend: "none",
   pieSliceText: "none",
   chartArea: { width: "90%", height: "90%" },
   pieSliceTextStyle: null,
   tooltip: {
      text: "percentage",
      fontSize: 20,
   },
   enableInteractivity: true,
   selectionMode: "none",
};

const PieChart = ({ chartData, profileImg }) => {
   return (
      <div className={styles.chart_container}>
         <div className={styles.chart}>
         <Chart
            chartType="PieChart"
            width="100%"
            height="100%"
            data={chartData}
            options={profileImg ? {...options, colors: ["#19B256", "#595959", "#FADC3E"]} : options}
         />
         </div>
         {profileImg ? (
            <span className={cn(styles.correct_percent, styles.img_wrapper)}>
               <img src={profileImg} alt={"profile image"} />
            </span>
         ) : (
            <span className={styles.correct_percent}>
               <span>{chartData[1][1] ? chartData[1][1] : '0'}</span>
               <div>/100%</div>
            </span>
         )}
      </div>
   );
};

export default PieChart;
