import React, { useEffect, useState, useRef } from "react";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import FolderApi from "../../../utils/api/FolderApi";
import { COLOR_PRESET_ORANGE, COLOR_PRESETS, COURSE_STATUS_ACTIVE } from "../../../constants/courses";
import { FOLDER_ICONS_LIST } from "../../../constants/folders";
import cn from "classnames";
import { toast } from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import DropdownCourses from "./DropdownCourses/DropdownCourses";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import StepIndicator from "../../StepIndicator/StepIndicator";
import SelectUsersAndTeamsModal from "../SelectUsersAndTeamsModal/SelectUsersAndTeamsModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import CourseApi from "../../../utils/api/CourseApi";
import useGetActiveUsersOfCompany from "../../../hooks/api/useGetActiveUsersOfCompany";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useUserRole from "../../../hooks/useUserRole";
import useGetTeams from "../../../hooks/api/useGetTeamsOfCompany";
import { useParams } from "react-router-dom";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import useFindTranlsations from '../../../hooks/useFindTranlsations';
import ColorsPicker from "../../ColorsPicker/ColorsPicker";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const AddFolderModal = ({
   onClose,
   isOpen,
   createSuccessCallback,
   onOpenSelectCourseModal,
   selectedCourses,
   onRemove,
   setSelectedCourses,
   isAddFolderLoading,
   isTopic,
   extraUsers = []
}) => {
   const [title, setTitle] = useState("");
   const [isDisabled, setIsDisabled] = useState(true);
   const [isChangeLoading, setIsChangeLoading] = useState(false);
   const [activeColor, setActiveColor] = useState(COLOR_PRESET_ORANGE);

   const [selectedDepartment, setSelectedDepartment] = useState();
   const [selectedPosition, setSelectedPosition] = useState();
   
   const t = useFindTranlsations();
    const tNew = useFindTranslationsByString();

   const params = useParams();

   const [selectedUserList, setSelectedUserList] = useState([]);
   const [selectedTeamList, setSelectedTeamList] = useState([]);

   const relatedToFolderUsersPlusSelected = [...selectedUserList, ...extraUsers]

   const handleSelectUsersAndTeamsModal = useHandleModal();

   const currentCompanyBranchId = useSelector(
      (state) => state.sidebar.currentCompanyBranchId
   );

   const companyId = useSelector(
      (state) => state.sidebar.companyId
   );

   const userId = useSelector(state => state?.user?.info?.id);
   const { userRoleName } = useUserRole(companyId);

   // Folder icons
   const [folderActiveIcon, setFolderActiveIcon] = useState(FOLDER_ICONS_LIST[0]);
   const [isIconsDropdownOpen, setIsIconsDropdownOpen] = useState(false);
   const activeIconRef = useRef(null);
   const dropdownIconsRef = useRef(null);

   const onCloseIconsDropdown = () => setIsIconsDropdownOpen(false);
   const onToggleIconsDropdown = () => setIsIconsDropdownOpen(!isIconsDropdownOpen);

   useOutsideClick(dropdownIconsRef, onCloseIconsDropdown, activeIconRef);

   const { setCurrentPage, userList, setUserList, searchedValue, isUsersLoading, totalUsers, setSearchedValue, selectAll: selectAllUsers, setSelectAll: setSelectAllUsers } = useGetActiveUsersOfCompany(companyId, false, false, selectedDepartment?.id, selectedPosition?.id);

   const lastUserRef = useIntersectionObserver(
      () => {
        if (!isUsersLoading && userList?.length < totalUsers) {
          if(userList?.length === 0) {
            setCurrentPage(1);
          } else {
            setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [isUsersLoading, totalUsers]
    );

   const teamManager = useGetTeams({ companyId, userRoleName });

   const lastTeamRef = useIntersectionObserver(
      () => {
        if (!teamManager.isTeamsLoading && teamManager.teamsList?.length < teamManager.totalTeams) {
          if(teamManager.teamsList?.length === 0) {
            teamManager.setCurrentPage(1);
          } else {
            teamManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [teamManager.isTeamsLoading, teamManager.totalTeams]
    );

   const onChangeTitle = (e) => setTitle(e?.target?.value);
   const onChangeColor = (id) => setActiveColor(id);
   const onChangeIcon = (item) => {
      setFolderActiveIcon(item);
      onCloseIconsDropdown();
   };

   const onCreateFolder = async () => {
      setIsDisabled(true);
      setIsChangeLoading(true);

      const color = COLOR_PRESETS.find(
         (item) => item?.id === activeColor
      )?.id?.toString();

      const resCreate = await new FolderApi().createFolder({
         name: title,
         color,
         icon: folderActiveIcon?.name,
         branch_id: currentCompanyBranchId,
         ...(isTopic && params?.id ? { parent_id: params?.id} : {}),
         ...(isTopic ? { is_topic: 1 } : {}),
      });

      if (resCreate?.error?.message) {
         setIsDisabled(false);
         setIsChangeLoading(false);
         return toast(<ToastMsg text={resCreate?.error?.message} isError />);
      }

      const resAddCourses = await new FolderApi().addCoursesToFolder(
         resCreate?.success?.data?.folder?.id,
         { courses: selectedCourses?.map((item) => item?.id) }
      );

      const resAddFolderVisibility = await new FolderApi().changeFolderVisibilityForUsers(
         resCreate?.success?.data?.folder?.id,
         { user_data: [
            {
              user_id: userId,
              add: true
            }
          ] }
      );

      if(isTopic && selectedUserList?.length) {
         //  Add visibility for parent folder
         const resAddFolderVisibility = await new FolderApi().changeFolderVisibilityForUsers(
            params?.id,
            { user_data: selectedUserList?.map(user => ({
               user_id: user?.id,
               add: true
           }))}
         );
      }

      if(relatedToFolderUsersPlusSelected?.length) {
         const usersVisibilityData = { 
            user_data: relatedToFolderUsersPlusSelected?.map(user => ({
                user_id: user?.id,
                add: true
            }))
        };

        const usersData = {
            user_ids: relatedToFolderUsersPlusSelected?.map(user => user?.id),
            course_ids: selectedCourses?.map((item) => item?.id),
            status: COURSE_STATUS_ACTIVE,
            notification_for_folder_id: resCreate?.success?.data?.folder?.id,
            overwrite_user_course_active_or_paused_statuses: false
         }

         const usersVisibilityRes = await new FolderApi().changeFolderVisibilityForUsers(resCreate?.success?.data?.folder?.id, usersVisibilityData)

         if(selectedCourses?.length > 0) {
            const usersAccessRes = await new CourseApi().addAcceessOfFewCoursesToFewUsers(usersData)
         } 
      }

      if(selectedTeamList?.length) {
         const teamsData = {
            team_ids: selectedTeamList?.map(user => user?.id),
            course_ids: selectedCourses?.map((item) => item?.id),
            status: COURSE_STATUS_ACTIVE,
            notification_for_folder_id: resCreate?.success?.data?.folder?.id,
            send_email: false
         }

         const teamsVisibilityData = { 
            folder_ids: [resCreate?.success?.data?.folder?.id],
            team_ids: selectedTeamList?.map(item => item?.id),
            is_visible: 1
         };

         if(isTopic) {
            //  Add visibility for parent folder
            teamsVisibilityData.folder_ids = [
               resCreate?.success?.data?.folder?.id, 
               +params?.id
           ];
         }
         
        const teamsVisibilityRes = await new FolderApi().changeFolderVisibilityForTeams(teamsVisibilityData)

        if(selectedCourses?.length) {
          await new CourseApi().addAcceessOfFewCoursesToFewTeams(teamsData);
        } 
      }

      if (resAddCourses?.success?.message)
         toast(<ToastMsg text={resAddCourses?.success?.message} />);
      if (resAddCourses?.error?.message)
         toast(<ToastMsg text={resAddCourses?.error?.message} isError />);

      createSuccessCallback();
      setIsDisabled(false);
      setIsChangeLoading(false);
      setSelectedCourses([]);
      setTitle('');
   }

   const onNextStep = () => {
      handleSelectUsersAndTeamsModal.open();
   };

   useEffect(() => {
      if (title?.length) setIsDisabled(false);
      else setIsDisabled(true);
   }, [title]);

   useEffect(() => {
      return ()=> {
         setUserList([]);
      }
   }, []);

   const closeModal = () => {
      onClose();
      setTitle("");
      setSelectedCourses([])
   };

   return (
     <>
       <ModalLayout
         onClose={closeModal}
         isOpen={isOpen}
         maxHeight={isTopic ? "350px" : "420px"}
         maxWidth={"416px"}
         withCross
       >
         <p className={styles.title}>
           {isTopic ? t?.createNewTopic : t?.createNewFolder}
         </p>
         <div className={styles.desc}>
           <Input
             max={40}
             placeholder={t?.title}
             value={title}
             onChangeValue={onChangeTitle}
             withCounter
             customClass={cn(styles.input, styles.input_height)}
           />
         </div>

         <DropdownCourses
           placeholder={t?.chooseCourses}
           value={selectedCourses.length ? `${tNew('Selected')}: ${selectedCourses.length}` : ''}
           data={selectedCourses}
           onClickPlus={onOpenSelectCourseModal}
           onClickMinus={onRemove}
         />
         {!isTopic && (
           <div>
             <div className={styles.colors}>
               <div>{t?.colourPresets}</div>
               <div className={styles.colors_wrapper}>
                   <ColorsPicker
                       activeColorId={activeColor}
                       onClick={(item) => onChangeColor(item?.id)}
                   />
                 <div
                   ref={activeIconRef}
                   onClick={onToggleIconsDropdown}
                   className={styles.folder_icon_wrapper}
                 >
                   <folderActiveIcon.Icon
                     className={styles.active_icon}
                     fill={"#fff"}
                   />
                 </div>
               </div>
             </div>

             {isIconsDropdownOpen && (
               <div ref={dropdownIconsRef} className={styles.icons_list}>
                 {FOLDER_ICONS_LIST?.map((item) => (
                   <item.Icon
                     key={item?.id}
                     className={cn(
                       styles.icon,
                       item?.id === folderActiveIcon?.id ? styles.active : "",
                     )}
                     fill={"rgba(147, 148, 149, 1)"}
                     onClick={() => onChangeIcon(item)}
                   />
                 ))}
               </div>
             )}
           </div>
         )}

         <div className={cn(isTopic ? styles.extraMargin : "")}>
           <Button title={t?.next} disabled={isDisabled} onClick={onNextStep} />
         </div>

         <StepIndicator step={1} bottom={"0"} classNames={styles.indicators} />

         {isChangeLoading && (
           <div className="default_loader_wrapper">
             <Loader size={"small"} />
           </div>
         )}
       </ModalLayout>

       <SelectUsersAndTeamsModal
         title={t?.addAccess}
         isAddAccess
         lastUserRef={lastUserRef}
         isUsersLoading={isUsersLoading || isChangeLoading}
         searchedValue={searchedValue}
         setSearchedValue={setSearchedValue}
         onClose={handleSelectUsersAndTeamsModal.close}
         isOpen={handleSelectUsersAndTeamsModal.isActive}
         selectedUsers={selectedUserList}
         setSelectedUsers={setSelectedUserList}
         usersList={userList}
         onConfirm={onCreateFolder}
         teamList={teamManager.teamsList}
         selectedTeamList={selectedTeamList}
         setSelectedTeamList={setSelectedTeamList}
         setSelectAllUsers={setSelectAllUsers}
         selectAllUsers={selectAllUsers}
         selectAllTeams={teamManager.selectAll}
         setSelectAllTeams={teamManager.setSelectAll}
         lastTeamRef={lastTeamRef}
         teamsLoading={teamManager.isTeamsLoading}
         teamSearchedValue={teamManager.searchedValue}
         setTeamSearchedValue={teamManager.setSearchedValue}
         selectedDepartment={selectedDepartment}
         setSelectedDepartment={setSelectedDepartment}
         selectedPosition={selectedPosition}
         setSelectedPosition={setSelectedPosition}
       />
     </>
   );
};

export default AddFolderModal;
