import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module.scss'
import {
    ACCESS_TABS,
    STUDENTS_TAB,
    STUDENTS_TABLE_DATA,
    STUDENT_JUST_FIELDS,
    STUDENT_JUST_HEADERS,
    TEAM_TAB,
    SELECT_ALL_ITEMS
} from "../../../constants/courses";
import cn from "classnames";
import Dropdown from "../../../components/Dropdown/Dropdown";
import SelectAllDropdown from "../../../components/SelectAllDropdown/SelectAllDropdown";
import cross from '../../../assets/images/symbols/cross_grey.svg';
import plus from '../../../assets/images/symbols/plus.svg';
import searchImg from '../../../assets/images/symbols/Search.svg';
import csvImg from '../../../assets/images/symbols/scv.svg';
import activeImg from '../../../assets/images/symbols/arrow_green.svg';
import pauseImg from '../../../assets/images/pause_icon.svg';
import deleteImg from '../../../assets/images/close.svg';
import img from '../../../assets/images/Image_orange.png'
import Button from "../../../components/Button/Button";
import {CSVLink} from 'react-csv';
import useHandleModal from '../../../hooks/dom/useHandleModal';
import { v4 as uuidv4 } from "uuid";
import CustomStaticTooltip from '../../../components/CustomStaticTooltip/CustomStaticTooltip';

const statusButtons = [
   { imgSrc: activeImg, label: "Active", desc: "The user may take the test" },
   { imgSrc: pauseImg, label: "Paused", desc: "The user cannot continue learning, but the lessons are available to him. If there is a deadline, it will be suspended" },
   { imgSrc: deleteImg, label: "Delete", desc: "The user does not see the course. His progress in the course will be deleted irretrievably" }
 ];

import TeamApi from '../../../utils/api/TeamApi';
import { ROLE_ID_STUDENT } from '../../../constants/user';
import UsersPageTable from '../UsersTable/UsersTable';
import SelectUsersAndTeamsModal from '../../../components/Modals/SelectUsersAndTeamsModal/SelectUsersAndTeamsModal';
import { mapUsersWithFilteredRoles, mapUsersWithNewLinks, returnTemporaryLinks } from '../../../utils/usersHelper';
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';

const AccessToTheCourseTab = ({
                                  selectedUserList,
                                  setSelectedUserList,
                                  selectedTeamList,
                                  setSelectedTeamList,
                                  userList,
                                  lastModalUserRef,
                                  modalUserSearchedValue,
                                  setModalUserSearchedValue,
                                  modalTeamSearchedValue,
                                  setModaTeamrSearchedValue,
                                  changeAdminAsStudentStatus,
                                  teamList,
                                  courseUserList,
                                  fetchTeams,
                                  course,
                                  refetchData,
                                  loading,
                                  isModalUsersLoading,
                                  changeUserStatus,
                                  deleteUserFromTable,
                                  onGiveAccess,
                                  companyId,
                                  lastCourseUserRef,
                                  courseSearchedValue,
                                  setCourseSearchedValue,
                                  addAccessLoading,
                                  isUsersLoading,
                                  selectAllUsers,
                                  setSelectAllUsers,
                                  selectAllTeams,
                                  setSelectAllTeams,
                                  lastTeamRef,
                                  teamsLoading,
                                  fetchUsersOfCourse,
                                  orderBy,
                                  setOrderBy,
                                  order,
                                  setOrder,
                                  changeSelectAllMode,
                                  selectedUserListChangeStatus,
                                  setSelectedUserListChangeStatus,
                                  selectMode,
                                  onClickRow,
                                  openConfirmChangeStatusesModal,
                                  toggleSelectAllUsersInTable,
                                  emails,
                                  setEmails,
                                  progress,
                                  isCancelRequestsRef,
                                  selectedDepartment,
                                  setSelectedDepartment,
                                  selectedPosition,
                                  setSelectedPosition
                              }) => {
    const [activeTab, setActiveTab] = useState(STUDENTS_TAB);
    const [searchValue, setSearchValue] = useState('');

    const t = useFindTranslationsByString();

    const selectUsersAndTeamsModal = useHandleModal();
    
    const csvLinkRef = useRef(null);

    const onClickTab = (value) => setActiveTab(value);

    const filterTeamList = (team) => !selectedTeamList?.some(item => item?.id === team?.id) && team?.name;

    const onOpenAddAccessModal = () => {
      isCancelRequestsRef.current = null;
      selectUsersAndTeamsModal?.open();
   }

   const onCancelRequests = () => {
      isCancelRequestsRef.current = true;
      selectUsersAndTeamsModal.close();
    }

    const csvData = [
       STUDENT_JUST_HEADERS,
      ...courseUserList?.map(({ team, name, date, position, role, status, department, progress}) => [
         team,
         name,
         department,
         position,
         role,
         date,
         progress,
         status
      ]),
    ];

   useEffect(() => {
      setSearchValue('');
      setSelectedUserList([]);
   }, [activeTab]);

   useEffect(() => {
      setSearchValue('');
      setSelectedUserList([]);
   }, [activeTab]);

    return (
        <div translate="no" className={styles.accessToTheCourseTab}>

            <div className={styles.leftBlock}>
                <div className={styles.search_wrapper}>
                     <div className={styles.selectAllDropdown}>
                        <SelectAllDropdown
                           minHeight={"200px"}
                           data={SELECT_ALL_ITEMS}
                           selectedItems={selectedUserListChangeStatus}
                           setSelectedItems={setSelectedUserListChangeStatus}
                           onChange={changeSelectAllMode}
                           isActive={selectMode?.length}
                        />
                     </div>
                    {selectMode?.length > 0 &&
                      <div className={styles.status_btns}>
                        {statusButtons.map((button, index) => (
                           <button 
                              onClick={()=> 
                                 selectedUserListChangeStatus?.length &&openConfirmChangeStatusesModal(button?.label)}
                              key={index}
                              className={cn(
                                 styles.changeStatusBtn,
                                 !selectedUserListChangeStatus?.length ? styles.inactiveBtn : ''
                              )}
                           >
                              <img src={button.imgSrc} alt={button.label} />
                              <span>{t(button.label)}</span>

                              <p className={styles.status_hint}>
                                 {t(button?.desc)}
                              </p>
                           </button>
                        ))}
                     </div>
                    }
                    <div className={styles.search}>
                        <img src={searchImg} alt={''}/>
                        <input
                            placeholder={t('Search')}
                            value={courseSearchedValue}
                            onChange={(e) => setCourseSearchedValue(e.target.value)}/>
                    </div>

               <Button 
                  onClick={()=> fetchUsersOfCourse(1, 1000).then(()=> csvLinkRef.current.link.click())}
                  title={t('Export to CSV')}
                  isBlack image={csvImg} 
                  maxWidth={'220px'}
                  disabled={loading}
               />

               <CSVLink ref={csvLinkRef} style={{ textDecoration: "none" }} data={csvData}/>

               <Button onClick={onOpenAddAccessModal} title={t('Add access')} isBlack image={plus} maxWidth={'230px'}/>

                </div>

                  <div className={styles.table_wrapper}>
                     <UsersPageTable
                        columns={selectMode ? ['checkbox', ...STUDENT_JUST_FIELDS] : STUDENT_JUST_FIELDS}
                        data={courseUserList}
                        tableFor={"students"}
                        changeUserStatus={changeUserStatus}
                        deleteUserFromTable={deleteUserFromTable}
                        loading={loading}
                        refetchCourseUsers={refetchData}
                        lastUserRef={lastCourseUserRef}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        order={order}
                        setOrder={setOrder}
                        selectMode={selectMode}
                        selectedUserListChangeStatus={selectedUserListChangeStatus}
                        onClickRow={onClickRow}
                        toggleSelectAllUsersInTable={toggleSelectAllUsersInTable}
                     />
                  </div>
            </div>

            <SelectUsersAndTeamsModal
                onClose={selectUsersAndTeamsModal.close}
                isOpen={selectUsersAndTeamsModal.isActive}
                setSelectedUsers={setSelectedUserList}
                selectedUsers={selectedUserList}
                searchedValue={modalUserSearchedValue}
                setSearchedValue={setModalUserSearchedValue}
                usersList={userList}
                onConfirm={onGiveAccess}
                teamList={teamList}
                isUsersLoading={isModalUsersLoading || isUsersLoading}
                lastUserRef={lastModalUserRef}
                setSelectedTeamList={setSelectedTeamList}
                selectedTeamList={selectedTeamList}
                changeAdminAsStudentStatus={changeAdminAsStudentStatus}
                selectAllUsers={selectAllUsers}
                setSelectAllUsers={setSelectAllUsers}
                selectAllTeams={selectAllTeams}
                setSelectAllTeams={setSelectAllTeams}
                lastTeamRef={lastTeamRef}
                teamsLoading={teamsLoading}
                teamSearchedValue={modalTeamSearchedValue}
                setTeamSearchedValue={setModaTeamrSearchedValue}
                isCourseAccessPage
                emails={emails}
                setEmails={setEmails}
                progress={progress}
                onCancelRequests={onCancelRequests}
                selectedDepartment={selectedDepartment}
                setSelectedDepartment={setSelectedDepartment}
                selectedPosition={selectedPosition}
                setSelectedPosition={setSelectedPosition}
            />
            
        </div>
    );
};

export default AccessToTheCourseTab;