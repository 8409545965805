import React, { useRef } from "react";
import csvImg from "../../../assets/images/symbols/scv.svg";

import Button from "../../../components/Button/Button";

import styles from "./styles.module.scss";
import { CSVLink } from "react-csv";
import { BIGGER_DATA_LIMIT, ROLE_ID_STUDENT } from "../../../constants/user";
import { useSelector } from "react-redux";
import useUserRole from "../../../hooks/useUserRole";
import ExtendedDateDropdown from "../../../components/ExtendedDateDropdown/ExtendedDateDropdown";
import useFindTranslations from "../../../hooks/useFindTranlsations";
import KnowledgeCheckFilters from "../KnowledgeCheckFilters/KnowledgeCheckFilters";

const KnowledgeSubheader = ({
  searchedValue,
  setSearchedValue,
  refetchData,
  csvData,
  activeFilter,
  setActiveFilter,
  checked,
  students,
  studentsSearchedValue,
  setStudentsSearchedValue,
  selectedStudents,
  setSelectedStudents,
  lastStudentRef,
  onFilterApply,
  dateFilter,
  setDateFilter,
  isTeamPage,
  frontTimeFilterValue,
  setFrontTimeFilterValue,
  fetchTests,
  isDataLoading,
  setSelectedTeams,
  selectedTeams,
}) => {
  const csvLinkRef = useRef(null);

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const t = useFindTranslations();

  const handleStudentsFilterClick = (user) => {
    if (selectedStudents?.find((item) => item?.id === user?.id))
      setSelectedStudents(
        selectedStudents?.filter((item) => item?.id !== user?.id),
      );
    else setSelectedStudents([...selectedStudents, user]);
  };

  const handleTeamsFilterClick = (team) => {
    if (selectedTeams?.find((item) => item?.id === team?.id))
      setSelectedTeams(selectedTeams?.filter((item) => item?.id !== team?.id));
    else setSelectedTeams([...selectedTeams, team]);
  };

  const currentCompanyId = useSelector((state) => state.sidebar.companyId);
  const { userRoleId } = useUserRole(currentCompanyId);

  return (
    <div className={styles.subheader}>
      <div className={styles.line}></div>
      <div className={styles.search_wrapper}>
        <div className={styles.search}>
          <input
            placeholder={t?.search}
            value={searchedValue}
            onChange={(e) => setSearchedValue(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.filterBlockDate}>
        <ExtendedDateDropdown
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          frontTimeFilterValue={frontTimeFilterValue}
          setFrontTimeFilterValue={setFrontTimeFilterValue}
        />
      </div>

      {userRoleId && userRoleId !== ROLE_ID_STUDENT && !isTeamPage && (
        <KnowledgeCheckFilters
          searchNeeded
          showStatusFilter={checked}
          students={students}
          field={"label"}
          placeholder={t?.users}
          activeFilter={activeFilter}
          handleStudentsFilterClick={handleStudentsFilterClick}
          studentsSearchedValue={studentsSearchedValue}
          setStudentsSearchedValue={setStudentsSearchedValue}
          selectedStudents={selectedStudents}
          setSelectedStudents={setSelectedStudents}
          lastStudentRef={lastStudentRef}
          handleFilterClick={handleFilterClick}
          handleTeamsFilterClick={handleTeamsFilterClick}
          onFilterApply={onFilterApply}
          setSelectedTeams={setSelectedTeams}
          selectedTeams={selectedTeams}
        />
      )}

      <div className={styles.csv_wrapper}>
        <Button
          withoutBorders
          colorLighter
          isBlack
          onClick={() =>
            fetchTests(1, BIGGER_DATA_LIMIT).then(() =>
              csvLinkRef.current.link.click(),
            )
          }
          title={t?.exportToCSV}
          image={csvImg}
          maxWidth={"180px"}
          disabled={isDataLoading}
        />

        <CSVLink
          ref={csvLinkRef}
          style={{ textDecoration: "none" }}
          data={csvData}
        />
      </div>
    </div>
  );
};

export default KnowledgeSubheader;
