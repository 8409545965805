import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import filtersImg from "../../../../assets/images/symbols/filters.svg";
import themeImg from "../../../../assets/images/symbols/theme.svg";
import deleteImg from "../../../../assets/images/symbols/delete.svg";
import eyeImg from "../../../../assets/images/symbols/eye_crossed.svg";
import plusImg from "../../../../assets/images/symbols/plus.svg";
import arrow from "../../../../assets/images/symbols/arrow.svg";
import searchImg from "../../../../assets/images/symbols/Search.svg";
import FilterRadio from "../../../../components/FilterRadio/FilterRadio";

import cn from "classnames";
import useOutsideClick from "../../../../hooks/dom/useOutsideClick";
import { useSelector } from "react-redux";
import {
   PART_OF_MENTOR_NAME,
   ROLE_ID_STUDENT,
} from "../../../../constants/user";
import useMoveScroll from "../../../../hooks/useMoveScroll";
import LeftRightErrows from "../../../../components/LeftRightArrows/LeftRightArrows";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import { v4 as uuidv4 } from "uuid";
import CustomStaticTooltip from "../../../../components/CustomStaticTooltip/CustomStaticTooltip";
import useUserRole from "../../../../hooks/useUserRole";

const coursesSortFiltersData = ["First new", "Old first"];

const TabSubheader = ({
   title,
   onClickPlus,
   activeStatusFilter,
   setActiveStatusFilter,
   setActiveSortFilter,
   activeSortFilter,
   isCoursesOnCoursesPage,
   searchedValue,
   setSearchedValue,
   isCourses,
   onClickCreateTopic,
   onRedirectSettingsPage,
   openCreateTopicModal,
   t
}) => {
   const [showOptions, setShowOptions] = useState(false);

   const asStudent = useSelector((state) => state.sidebar.isUserAsStudent);

   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

   const optionRef = useRef(null);
   const dropDownRef = useRef(null);

   const closeOptions = () => setShowOptions(false);

   useOutsideClick(dropDownRef, closeOptions, optionRef);

   const toggleOptions = (e) => {
      setShowOptions((prev) => !prev);
   };

   const handleTagsChange = (tag) => {
      if (activeTagsFilter?.find((item) => item === tag))
         setActiveTagsFilter(activeTagsFilter?.filter((item) => item !== tag));
      else setActiveTagsFilter([...activeTagsFilter, tag]);
   };

   const handleFolderNameChange = (name) => {
      setActiveTagsFilter(name);
   };

   return (
      <div className={styles.wrapper}>
         <div className={cn(styles.filtersBlock, "noselect")}>
            <FilterRadio
               key={"All"}
               title={t?.all}
               active={activeStatusFilter === null}
               onClick={() => setActiveStatusFilter(null)}
            />
            <FilterRadio
               key={"Hidden"}
               title={t?.hidden}
               active={activeStatusFilter === "hidden"}
               onClick={() => setActiveStatusFilter("hidden")}
            />
            <FilterRadio
               key={"Deleted"}
               title={t?.deletedText}
               active={activeStatusFilter === "deleted"}
               onClick={() => setActiveStatusFilter("deleted")}
            />

            <div className={styles.search}>
               <CustomStaticTooltip id={uuidv4()} icon={searchImg} />
               <input
                  placeholder={t?.search}
                  value={searchedValue}
                  onChange={(e) => {
                     setSearchedValue(e.target.value);
                  }}
               />
            </div>
            <div
               className={cn(
                  styles.block,
                  styles.sort_button,
                  coursesSortFiltersData.includes(activeSortFilter)
                     ? styles.activeFilter
                     : "",
                  showOptions ? styles.opened : ""
               )}
            >
               <div
                  className={styles.filter_wrapper}
                  ref={optionRef}
                  onClick={toggleOptions}
               >
                  <img src={filtersImg} alt={""} />
                  {coursesSortFiltersData.includes(activeSortFilter)
                     ? activeSortFilter
                     : t?.filter}
                  <img
                     className={cn(
                        styles.options_arrow,
                        showOptions ? styles.options_opened : ""
                     )}
                     src={arrow}
                     alt={""}
                  />
               </div>

               {showOptions && (
                  <div className={styles.options} ref={dropDownRef}>
                     {coursesSortFiltersData.map((item) => (
                        <span
                           className={
                              activeSortFilter === item
                                 ? styles.active_option
                                 : ""
                           }
                           key={item}
                           onClick={() => {
                              setActiveSortFilter(item);
                              closeOptions();
                           }}
                        >
                           {item}
                        </span>
                     ))}
                  </div>
               )}
            </div>

            {userRoleId &&
               !userRoleName?.includes(PART_OF_MENTOR_NAME) &&
               userRoleId !== ROLE_ID_STUDENT &&
               !asStudent && (
                  <div
                     className={cn(styles.block, styles.plus)}
                     onClick={() => {
                        if (isCourses) {
                           onClickPlus();
                        } else {
                           onClickCreateTopic();
                        }
                     }}
                  >
                     <CustomStaticTooltip id={uuidv4()} icon={plusImg} />
                     {isCourses ? t?.addCourses : t?.createTopic}
                  </div>
               )}
         </div>
      </div>
   );
};

export default TabSubheader;
