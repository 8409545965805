
export const validateUserCreation = (user, setErrors) => {
   const validationErrors = {};
   if (!user.name) validationErrors.name = "Name is required";

   // FOR FUTURE
   // if (!user.surname) validationErrors.surname = "Surname is required";
   
   if (!user.email) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
      validationErrors.email = "Invalid email format";
    }

   if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false; 
   }
   return true
};

export const validateSpecialUserCreation = (user, setErrors) => {
   const validationErrors = {};
   if (!user.name) validationErrors.name = "Name is required";
   
   // FOR FUTURE
   // if (!user.surname) validationErrors.surname = "Surname is required";
   if (!user.role) validationErrors.role = "Role is required";
   
   if (!user.email) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
      validationErrors.email = "Invalid email format";
    }

   if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false; 
   }
   return true
};
