import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import FiltersBlock from "./FiltersBlock/FiltersBlock";
import FolderBlock from "../../components/FolderBlock/FolderBlock";
import {
  COURSE_STATUS_DELETED,
  COURSE_STATUS_HIDEN,
  FOLDER_STATUS_ACTIVE,
  FOLDER_STATUS_DELETED,
  FOLDER_STATUS_HIDEN,
} from "../../constants/courses";
import CourseBlock from "../../components/CourseBlock/CourseBlock";
import useHandleModal from "../../hooks/dom/useHandleModal";
import AddCourseModal from "../../components/Modals/AddCourseModal/AddCourseModal";
import AddFolderModal from "../../components/Modals/AddFolderModal/AddFolderModal";
import FolderApi from "../../utils/api/FolderApi";
import CourseApi from "../../utils/api/CourseApi";
import { isMobileDevice } from "../../utils/usersHelper";
import SelectCourseModal from "../../components/Modals/SelectCourseModal/SelectCourseModal";
import arrow from "../../assets/images/arrow_right.svg";
import pausedIcon from "../../assets/images/symbols/pause.svg";
import Loader from "../../components/Loader/Loader";

import { useSelector } from "react-redux";
import {
  mapCoursesWithNewLinks,
  returnTemporaryCoursesLinks,
} from "../../utils/coursesHelper";
import useMoveScroll from "../../hooks/useMoveScroll";

import styles from "./styles.module.scss";
import cn from "classnames";
import {
  ROLE_ID_STUDENT,
  ROLE_ID_SUPER_ADMIN,
} from "../../constants/user";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import useDuplicateAgencies from "../../hooks/dom/useDuplicateAgencies";
import SelectAgencyModal from "../../components/Modals/SelectAgencyModal/SelectAgencyModal";
import useChangeCourse from "../../hooks/useChangeCourse";
import useChangeFolder from "../../hooks/useChangeFolder";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import useUserRole from "../../hooks/useUserRole";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useGetFolders from "../../hooks/api/useGetFolders";
import useFindTranlsations from "../../hooks/useFindTranlsations";
import useGetAgencies from "../../hooks/api/useGetAgencies";
import FolderInListItem from "../../components/FolderInListItem/FolderInListItem";
import { useNavigate, useParams } from "react-router-dom";
import { DEFAULT_TEXT } from "../../constants/languages";

const CoursesPage = () => {
  const [selectedCourses, setSelectedCourses] = useState([]);
  const navigate = useNavigate();
  const params = useParams();

  const [byFolder, setByFolder] = useState(false);
  const [foldersInListView, setFoldersInListView] = useState(false);
  const [openedFolderTabs, setOpenedFolderTabs] = useState([]);

  const userId = useSelector((state) => state?.user?.info?.id);

  const {
    blockWrapperRef,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isDragging,
  } = useMoveScroll();

  const onRedirectToEditFolderPage = (e, folder) => {
    e.stopPropagation();
    navigate(`/courses/folder/${folder?.id}/edit`);
  };

  const t = useFindTranlsations();

  const {
    onDuplicateAgencies,
    onOpenDuplicateModal,
    duplicateModal,
    setSelectedAgencies,
    selectedAgencies,
    isAgenciesDuplicateLoading,
  } = useDuplicateAgencies();
  
  const agencyManager = useGetAgencies(userId);

  const lastAgencyRef = useIntersectionObserver(() => {
    if (
      !agencyManager.isAgenciesLoading &&
      agencyManager.agenciesList?.length < agencyManager.totalAgencies
    ) {
      if (agencyManager.agenciesList?.length === 0) {
        agencyManager.setCurrentPage(1);
      } else {
        agencyManager.setCurrentPage((prev) => prev + 1);
      }
    }
  }, [agencyManager.isAgenciesLoading, agencyManager.totalAgencies]);

  const handleAddCourseModal = useHandleModal();
  const handleAddFolderModal = useHandleModal();
  const handleSelectCourseModal = useHandleModal();

  const currentCompanyId = useSelector((state) => state.sidebar.companyId);
  const { userRoleId } = useUserRole(currentCompanyId);
  const [allTags, setAllTags] = useState([]);
  const [allTopics, setAllTopics] = useState([]);

  const isUserAsStudent = useSelector((state) => state.sidebar.isUserAsStudent);

  const onRemove = (id) =>
    setSelectedCourses(selectedCourses?.filter((item) => item?.id !== id));

  const getAllTags = async () => {
    const res = await new CourseApi().getAllTags();
    if (res?.success?.data) {
      setAllTags(res?.success?.data);
    }
  };

  const getAllYourTopics = async () => {
    const res = await new FolderApi().getAllYourActiveTopics(currentCompanyId);

    if (res?.success?.data) {
      setAllTopics(res?.success?.data);
    }
  };

  useEffect(() => {
    if (currentCompanyId) getAllYourTopics();
  }, [currentCompanyId]);

  useEffect(() => {
    getAllTags();
  }, []);

  const isUserAsStudentAndNotSuperAdmin =
    userRoleId === ROLE_ID_SUPER_ADMIN ? false : isUserAsStudent;

  const isExcludeCoursesWhereAdminAsStudent =
    userRoleId !== ROLE_ID_SUPER_ADMIN && userRoleId !== ROLE_ID_STUDENT;

  const {
    courseList,
    isCoursesLoading,
    refetchCourses,
    totalCourses,
    setCurrentPage,
    currentPage,
    activeCourseTagsFilter,
    setActiveCourseTagsFilter,
    activeCourseSortFilter,
    setActiveCourseSortFilter,
    activeCourseFilter,
    setActiveCourseFilter,
    studentCourseFilter,
    setStudentCourseFilter,
    searchedValue,
    setSearchedValue,
    selectAll,
    setSelectAll,
    selectedLanguages,
    onSelectedLanguagesChange,
    clearExtendedFilters,
    setSearchByName,
  } = useGetYourCoursesInCompany(
    currentCompanyId,
    isUserAsStudentAndNotSuperAdmin,
    null,
    null,
    isExcludeCoursesWhereAdminAsStudent,
  );

  useEffect(() => {
    if (params?.filterId) {
      setStudentCourseFilter(params?.filterId === "1" ? "completed" : "");
    }
  }, [params]);

  const lastCourseRef = useIntersectionObserver(() => {
    if (!isCoursesLoading && courseList?.length < totalCourses) {
      if (courseList?.length === 0) {
        setCurrentPage(1);
      } else {
        setCurrentPage((prev) => prev + 1);
      }
    }
  }, [isCoursesLoading, totalCourses]);

  const {
    foldersList,
    isFoldersLoading,
    refetchFolders,
    totalFolders,
    setCurrentPage: setCurrentFolderPage,
    activeFolderStatusFilter,
    setActiveFolderStatusFilter,
    activeFolderSortFilter,
    setActiveFolderSortFilter,
    activeTopicFilter,
    setActiveTopicFilter,
    searchedValue: folderSearchValue,
    setSearchedValue: setFolderSearchValue,
    clearExtendedFilters: clearExtendedFiltersFolder,
  } = useGetFolders(currentCompanyId);

  const lastFolderRef = useIntersectionObserver(() => {
    if (!isFoldersLoading && foldersList?.length < totalFolders) {
      if (foldersList?.length === 0) {
        setCurrentFolderPage(1);
      } else {
        setCurrentFolderPage((prev) => prev + 1);
      }
    }
  }, [isFoldersLoading, totalFolders]);

  const isNotNeededCoursesInJson = true;
  const isTopic = false;
  const isCoursesImagesNeeded = false;

  //  folders inside by folder tab!
  const {
    foldersList: foldersList2,
    setFoldersList: setFoldersList2,
    isFoldersLoading: isFoldersLoading2,
    totalFolders: totalFolders2,
    setCurrentPage: setCurrentFolderPage2,
    activeFolderStatusFilter: activeFolderStatusFilter2,
    setActiveFolderStatusFilter: setActiveFolderStatusFilter2,
    activeFolderSortFilter: activeFolderSortFilter2,
    setActiveFolderSortFilter: setActiveFolderSortFilter2,
    activeTopicFilter: activeTopicFilter2,
    setActiveTopicFilter: setActiveTopicFilter2,
    searchedValue: folderSearchValue2,
    setSearchedValue: setFolderSearchValue2,
  } = useGetFolders(
    currentCompanyId,
    isCoursesImagesNeeded,
    isTopic,
    isNotNeededCoursesInJson,
  );

  const lastFolderRef2 = useIntersectionObserver(() => {
    if (!isFoldersLoading2 && foldersList2?.length < totalFolders2) {
      if (foldersList2?.length === 0) {
        setCurrentFolderPage2(1);
      } else {
        setCurrentFolderPage2((prev) => prev + 1);
      }
    }
  }, [isFoldersLoading2, totalFolders2]);

  const {
    onFolderHideToggle,
    onConfirmDeleteOpen,
    onFolderDeleteToggle,
    onConfirmOpenChangeHidenStatus,
    deleteFolder,
    confirmModalReturnBack,
    onConfirmOpenRestore: onConfirmOpenRestoreF,
    confirmDeleteModal: confirmDeleteModalF,
    confirmModal: confirmModalF,
    confirmHideModal: confirmHideModalF,
    confirmModalRestore: confirmModalRestoreF,
    isChangeLoading: isChangeLoadingF,
    onConfirmHideOpen: onConfirmHideOpenF,
    onConfirmOpenDelete: onConfirmOpenDeleteF,
  } = useChangeFolder(refetchFolders);

  const {
    isChangeLoading,
    confirmModalToogleShowStatus,
    confirmModal,
    confirmHideModal,
    confirmDeleteModal,
    retakeModal,
    onCourseChangeStatus,
    onConfirmOpenStatus,
    changeCourseStatusToActive,
    onConfirmOpenRestore,
    confirmModalRestore,
    onConfirmOpenHide,
    onRetakeCourse,
    onRetakeModalOpen,
    onConfirmPausedOpen,
    onRedirectClickedCourse,
    confirmPausedModal,
    onConfirmOpenDelete,
    deleteCourse,
    onConfirmOpen,
  } = useChangeCourse(refetchCourses, userId);

  const createFolderSuccessCallback = () => {
    refetchFolders();
    setSearchedValue("");
    handleAddFolderModal.close();
  };

  const currentFoldersNamesSet = new Set(
    foldersList?.reduce((allNames, folder) => {
      return allNames.concat(folder?.title ?? []);
    }, []) ?? [],
  );

  //  For folders inside byFolder tab
  const currentFoldersNamesSet2 = new Set(
    foldersList2?.reduce((allNames, folder) => {
      return allNames.concat(folder?.title ?? []);
    }, []) ?? [],
  );

  const currentFoldersNames2 = Array.from(currentFoldersNamesSet2);

  const handleActiveFolder = async (folder) => {
    if (!folder?.id) return;

    const folderExists = openedFolderTabs.some((item) => item.id === folder.id);

    if (folderExists) {
      // If folder exists, remove it
      setOpenedFolderTabs(
        openedFolderTabs.filter((item) => item.id !== folder.id),
      );
    } else {
      // If folder doesn't exist, add it
      setOpenedFolderTabs([...openedFolderTabs, folder]);

      if (!folder?.courses?.length) {
        const coursesInFolderRes =
          await new CourseApi().get100ActiveCoursesInFolder(
            currentCompanyId,
            folder?.id,
          );

        const courses = coursesInFolderRes?.success?.data;
        const filteredCourses = courses?.filter(
          (course) =>
            course?.image && !course?.image?.includes("/static/media/"),
        );
        const links = await returnTemporaryCoursesLinks(
          userId || 1,
          filteredCourses,
        );
        const coursesWithUpdatedLinks = courses?.map((course) =>
          mapCoursesWithNewLinks(course, links),
        );

        setFoldersList2((prev) => {
          const updatedFolders = prev.map((prevFolder) => {
            if (prevFolder.id === folder?.id) {
              // Replace the folder inside courses with your new variable
              const updatedFolder = {
                ...prevFolder,
                courses: coursesWithUpdatedLinks,
              };
              return updatedFolder;
            }
            return prevFolder;
          });
          return updatedFolders;
        });
      }
    }
  };

  return (
    <MainLayout>
      <div className={styles.coursesPage}>
        <div className={styles.filter_wrapper}>
          <FiltersBlock
            key={"folder_filters"}
            isFolders
            foldersInListView={foldersInListView}
            setFoldersInListView={setFoldersInListView}
            activeSortFilter={activeFolderSortFilter}
            setActiveSortFilter={setActiveFolderSortFilter}
            setActiveTagsFilter={setActiveTopicFilter}
            activeTagsFilter={activeTopicFilter}
            onClickPlus={handleAddFolderModal.open}
            setActiveFilter={setActiveFolderStatusFilter}
            activeFilter={activeFolderStatusFilter}
            currentTags={allTopics}
            combinedFilters
            isFolderOnCoursesPage={true}
            paginatedSearchedValue={folderSearchValue}
            setPaginatedSearchedValue={setFolderSearchValue}
            createHint={t?.createNewFolder}
            clearExtendedFilters={clearExtendedFiltersFolder}
          />
        </div>

        {!foldersInListView && (
          <div
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            ref={blockWrapperRef}
            className={styles.list_folders}
          >
            {isFoldersLoading && foldersList?.length === 0 && (
              <div className={styles.w_full}>
                {Array(4)
                  .fill(null)
                  .map((item, idx) => (
                    <div
                      key={`folder_teamplate_first${idx}`}
                      className={cn(styles.loadingSkeleton, styles.loadingSkeletonFolder)}
                    >
                      <div className={styles.loadingTitle}></div>
                    </div>
                  ))}
              </div>
            )}
            {foldersList?.map((item, idx) => (
              <FolderBlock
                onRedirectToEditFolderPage={onRedirectToEditFolderPage}
                isDragging={isDragging}
                key={`folder_teamplate${idx}`}
                refetchFolders={refetchFolders}
                folder={item}
                activeFilter={activeFolderStatusFilter}
                onConfirmDeleteOpen={onConfirmDeleteOpen}
                onConfirmHideOpen={onConfirmHideOpenF}
                onConfirmOpenRestore={onConfirmOpenRestoreF}
                onConfirmOpenDelete={onConfirmOpenDeleteF}
                onConfirmOpenChangeHidenStatus={onConfirmOpenChangeHidenStatus}
                lastFolderRef={
                  foldersList?.length - 1 === idx ? lastFolderRef : null
                }
              />
            ))}

            {foldersList?.length === 0 && !isFoldersLoading && <EmptyBlock />}
          </div>
        )}

        {foldersInListView && (
          <div className={styles.actual_list_folders}>
            {isFoldersLoading && foldersList?.length === 0 && (
              <div className={styles.w_full}>
                {Array(4)
                  .fill(null)
                  .map((item, idx) => (
                    <div
                      key={`folder_teamplate_first${idx}`}
                      className={styles.loadingSkeleton}
                    >
                      <div className={styles.loadingTitle}></div>
                    </div>
                  ))}
              </div>
            )}
            {foldersList?.map((item, idx) => (
              <FolderInListItem
                onRedirectToEditFolderPage={onRedirectToEditFolderPage}
                key={`folder_teamplate_list${idx}`}
                refetchFolders={refetchFolders}
                folder={item}
                activeFilter={activeFolderStatusFilter}
                onConfirmDeleteOpen={onConfirmDeleteOpen}
                onConfirmHideOpen={onConfirmHideOpenF}
                onConfirmOpenRestore={onConfirmOpenRestoreF}
                onConfirmOpenDelete={onConfirmOpenDeleteF}
                onConfirmOpenChangeHidenStatus={onConfirmOpenChangeHidenStatus}
                lastFolderRef={
                  foldersList?.length - 1 === idx ? lastFolderRef : null
                }
              />
            ))}

            {foldersList?.length === 0 && !isFoldersLoading && (
              <EmptyBlock text={t?.folders} />
            )}
          </div>
        )}

        {!byFolder && (
          <div className={styles.filter_wrapper}>
            <FiltersBlock
              key={"course_filters"}
              setActiveFilter={setActiveCourseFilter}
              activeFilter={activeCourseFilter}
              activeTagsFilter={activeCourseTagsFilter}
              setActiveTagsFilter={setActiveCourseTagsFilter}
              activeSortFilter={activeCourseSortFilter}
              setActiveSortFilter={setActiveCourseSortFilter}
              studentCourseFilter={studentCourseFilter}
              setStudentCourseFilter={setStudentCourseFilter}
              onClickPlus={handleAddCourseModal.open}
              currentTags={allTags}
              searchWhat={
                DEFAULT_TEXT?.courses_and_folders?.searchCourseNameTags
              }
              isCoursesOnCoursesPage
              isCourses
              setByFolder={setByFolder}
              byFolder={byFolder}
              paginatedSearchedValue={searchedValue}
              setPaginatedSearchedValue={setSearchedValue}
              createHint={t?.createNewCourse}
              combinedFilters
              onSelectedLanguagesChange={onSelectedLanguagesChange}
              selectedLanguages={selectedLanguages}
              clearExtendedFilters={clearExtendedFilters}
            />
          </div>
        )}

        {!byFolder && (
          <div className={styles.list}>
            {isCoursesLoading && courseList?.length === 0 && (
              <div className={cn(styles.w_full, styles.w_full_courses)}>
                {Array(4)
                  .fill(null)
                  .map((item, idx) => (
                    <div
                      key={`course_template_first${idx}`}
                      className={styles.sceletonCourse_wrapper}
                    >
                      <div className={cn(styles.sceletonCourse)}></div>
                      <div className={styles.loadingTitleCourse}></div>
                    </div>
                  ))}
              </div>
            )}
            {courseList?.map((item, key) => (
              <CourseBlock
                maxWidth={"100%"}
                lastCourseRef={
                  courseList?.length - 1 === key ? lastCourseRef : null
                }
                refetchCourses={refetchCourses}
                key={`course_${item?.id}`}
                activeCourseFilter={activeCourseFilter}
                studentCourseFilter={studentCourseFilter}
                course={item}
                onOpenDuplicateModal={onOpenDuplicateModal}
                onConfirmOpenStatus={onConfirmOpenStatus}
                onConfirmOpenRestore={onConfirmOpenRestore}
                onConfirmOpenHide={onConfirmOpenHide}
                onRetakeModalOpen={onRetakeModalOpen}
                onConfirmPausedOpen={onConfirmPausedOpen}
                onConfirmOpenDelete={onConfirmOpenDelete}
                onConfirmOpen={onConfirmOpen}
                isLoading={isCoursesLoading}
              />
            ))}
          </div>
        )}

        {isCoursesLoading && courseList?.length > 0 && (
          <div
            className={cn(
              "default_loader_wrapper",
              currentPage > 1 ? styles.loader_more_wrapper : "",
            )}
          >
            <Loader size={"small"} />
          </div>
        )}

        {isFoldersLoading && foldersList?.length > 0 && (
          <div className={"default_loader_wrapper"}>
            <Loader size={"small"} />
          </div>
        )}

        {byFolder && (
          <div className={styles.folders_with_courses_wrapper}>
            <div className={styles.folder_in_tab_filters}>
              <FiltersBlock
                key={"folder_filters_second"}
                activeSortFilter={activeFolderSortFilter2}
                setActiveSortFilter={setActiveFolderSortFilter2}
                setActiveTagsFilter={setActiveTopicFilter2}
                activeTagsFilter={activeTopicFilter2}
                onClickPlus={handleAddFolderModal.open}
                setByFolder={setByFolder}
                byFolder={byFolder}
                setActiveFilter={setActiveFolderStatusFilter2}
                isCourses
                activeFilter={activeFolderStatusFilter2}
                currentTags={currentFoldersNames2}
                isFolderOnCoursesPage={true}
                paginatedSearchedValue={folderSearchValue2}
                setPaginatedSearchedValue={setFolderSearchValue2}
                combinedFilters
                clearExtendedFilters={clearExtendedFilters}
              />
            </div>
            {isCoursesLoading ? (
              <div className={styles.w_full}>
                {Array(4)
                  .fill(null)
                  .map((item, idx) => (
                    <div
                      key={`folders_teamplate_second${idx}`}
                      className={styles.sceletonCourse_wrapper}
                    >
                      <div className={cn(styles.sceletonCourse)}></div>
                      <div className={styles.loadingTitleCourse}></div>
                    </div>
                  ))}
              </div>
            ) : (
              foldersList2?.map((folder, idx) => {
                const isFolderOpened = openedFolderTabs?.some(
                  (item) => item.id === folder.id,
                );
                return (
                  <div
                    ref={
                      foldersList2?.length - 1 === idx ? lastFolderRef2 : null
                    }
                    key={`folder_second${idx}`}
                    className={cn(styles.folders_with_courses)}
                  >
                    <div
                      onClick={() => handleActiveFolder(folder)}
                      className={cn(
                        styles.header,
                        isFolderOpened && styles.opened,
                      )}
                    >
                      <h2>{folder?.title}</h2>
                      <img
                        className={cn(
                          styles.arrow,
                          isFolderOpened ? styles.arrow_opened : "",
                        )}
                        src={arrow}
                        alt=""
                      />
                    </div>

                    {isFolderOpened && (
                      <div className={styles.list_wrapper}>
                        <div
                          className={cn(
                            styles.list,
                            styles.folder_courses,
                            styles.opened,
                          )}
                        >
                          {folder?.courses?.map((item, key) => (
                            <CourseBlock
                              isByFolder={true}
                              maxWidth={"309px"}
                              refetchCourses={refetchCourses}
                              key={`course_inFolder${item?.id}`}
                              activeCourseFilter={activeCourseFilter}
                              studentCourseFilter={studentCourseFilter}
                              course={item}
                              onOpenDuplicateModal={onOpenDuplicateModal}
                              onConfirmOpenStatus={onConfirmOpenStatus}
                              onConfirmOpenRestore={onConfirmOpenRestore}
                              onConfirmOpenHide={onConfirmOpenHide}
                              onRetakeModalOpen={onRetakeModalOpen}
                              onConfirmOpenDelete={onConfirmOpenDelete}
                              onConfirmOpen={onConfirmOpen}
                              onConfirmPausedOpen={onConfirmPausedOpen}
                              isLoading={isCoursesLoading}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            )}

            {foldersList2?.length === 0 && !isFoldersLoading2 && (
              <div>
                <EmptyBlock />
              </div>
            )}
          </div>
        )}

        {courseList?.length === 0 && !isCoursesLoading && !byFolder && (
          <div>
            <EmptyBlock />
          </div>
        )}
      </div>

      {/* FOLDERS MODALS */}
      <ConfirmModal
        isRemove
        confirmButtonText={t?.deleteFromTheBin}
        onConfirm={deleteFolder}
        onClose={confirmDeleteModalF.close}
        isOpen={confirmDeleteModalF.isActive}
        isLoading={isChangeLoadingF}
        title={t?.areYouSure}
        subtitle={t?.youWillNotBeAbleToChangeYourChoice}
      />

      <ConfirmModal
        isRemove
        confirmButtonText={t?.deleteText}
        onConfirm={() => onFolderDeleteToggle(FOLDER_STATUS_DELETED)}
        onClose={confirmModalF.close}
        isOpen={confirmModalF.isActive}
        isLoading={isChangeLoadingF}
        title={t?.areYouSure}
        maxHeight={isMobileDevice() ? "270px" : '300px'}
      />

      <ConfirmModal
        confirmButtonText={t?.hide}
        onConfirm={() => onFolderHideToggle(FOLDER_STATUS_HIDEN)}
        onClose={confirmHideModalF.close}
        isOpen={confirmHideModalF.isActive}
        isLoading={isChangeLoadingF}
        title={t?.areYouSure}
        maxHeight={isMobileDevice() ? "270px" : '300px'}
      />

      <ConfirmModal
        confirmButtonText={t?.restore}
        onConfirm={() => onFolderDeleteToggle(FOLDER_STATUS_ACTIVE)}
        onClose={confirmModalRestoreF.close}
        isOpen={confirmModalRestoreF.isActive}
        isLoading={isChangeLoadingF}
        title={t?.areYouSure}
        maxHeight={isMobileDevice() ? "270px" : '300px'}
      />

      <ConfirmModal
        confirmButtonText={t?.confirm}
        onConfirm={() => onFolderHideToggle(FOLDER_STATUS_ACTIVE)}
        onClose={confirmModalReturnBack.close}
        isOpen={confirmModalReturnBack.isActive}
        isLoading={isChangeLoadingF}
        title={t?.areYouSure}
        maxHeight={isMobileDevice() ? "270px" : '300px'}
      />

      {/* COURSES MODALS */}

      <AddCourseModal
        isOpen={handleAddCourseModal.isActive}
        onClose={handleAddCourseModal.close}
      />

      <AddFolderModal
        selectedCourses={selectedCourses}
        setSelectedCourses={setSelectedCourses}
        onOpenSelectCourseModal={() => {
          handleSelectCourseModal.open();
          handleAddFolderModal.close();
          setSearchedValue("");
        }}
        isOpen={handleAddFolderModal.isActive}
        onClose={() => {
          handleAddFolderModal.close();
          setSearchedValue("");
        }}
        createSuccessCallback={createFolderSuccessCallback}
        coursesList={courseList}
        onRemove={onRemove}
      />

      <SelectCourseModal
        onClose={() => {
          handleSelectCourseModal.close();
          handleAddFolderModal.open();
        }}
        isOpen={handleSelectCourseModal.isActive}
        setSelectedCourses={setSelectedCourses}
        selectedCourses={selectedCourses}
        coursesList={courseList}
        lastCourseRef={lastCourseRef}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        coursesLoading={isCoursesLoading}
        searchedValue={searchedValue}
        setSearchedValue={setSearchedValue}
        setSearchByName={setSearchByName}
      />

      <SelectAgencyModal
        onClose={() => {
          setSelectedAgencies([]);
          duplicateModal.close();
        }}
        isOpen={duplicateModal.isActive}
        selectedAgencies={selectedAgencies}
        setSelectedAgencies={setSelectedAgencies}
        agenciesList={agencyManager.agenciesList}
        onAddAgencies={onDuplicateAgencies}
        refetch={refetchCourses}
        agencyManager={agencyManager}
        isDataLoading={
          agencyManager.isAgenciesLoading || isAgenciesDuplicateLoading
        }
        lastAgencyRef={lastAgencyRef}
      />

      <ConfirmModal
        confirmButtonText={t?.confirm}
        onConfirm={() =>
          changeCourseStatusToActive(confirmModalToogleShowStatus)
        }
        onClose={confirmModalToogleShowStatus.close}
        isOpen={confirmModalToogleShowStatus.isActive}
        isLoading={isChangeLoading}
        title={t?.areYouSure}
        maxHeight={isMobileDevice() ? "270px" : '300px'}
      />

      <ConfirmModal
        confirmButtonText={t?.restore}
        onConfirm={() => changeCourseStatusToActive(confirmModalRestore)}
        onClose={confirmModalRestore.close}
        isOpen={confirmModalRestore.isActive}
        isLoading={isChangeLoading}
        title={t?.areYouSure}
        maxHeight={isMobileDevice() ? "270px" : '300px'}
      />

      <ConfirmModal
        confirmButtonText={t?.hideText}
        onConfirm={() => onCourseChangeStatus(COURSE_STATUS_HIDEN)}
        onClose={confirmHideModal.close}
        isOpen={confirmHideModal.isActive}
        isLoading={isChangeLoading}
        title={t?.areYouSure}
        maxHeight={isMobileDevice() ? "270px" : '300px'}
      />
      <ConfirmModal
        confirmButtonText={t?.retakeCourse}
        onConfirm={onRetakeCourse}
        onClose={retakeModal.close}
        isOpen={retakeModal.isActive}
        title={t?.areYouSure}
        maxHeight={isMobileDevice() ? "270px" : '300px'}
      />
      <ConfirmModal
        icon={pausedIcon}
        confirmButtonText={t?.returnTranslation('Open course')}
        onConfirm={onRedirectClickedCourse}
        onClose={confirmPausedModal.close}
        isOpen={confirmPausedModal.isActive}
        title={t?.courseWasPaused}
        subtitle={t?.returnTranslation('You cannot continue learning, but completed lessons and finished tests are available. If there is a deadline, it will be suspended')}
      />
      <ConfirmModal
        isRemove
        confirmButtonText={t?.deleteFromTheBin}
        onConfirm={deleteCourse}
        onClose={confirmDeleteModal.close}
        isOpen={confirmDeleteModal.isActive}
        isLoading={isChangeLoading}
        title={t?.areYouSure}
        subtitle={t?.youWillNotBeAbleToChangeYourChoice}
        maxHeight={isMobileDevice() ? "270px" : '300px'}
      />
      <ConfirmModal
        isRemove
        confirmButtonText={t?.deleteText}
        onConfirm={() => onCourseChangeStatus(COURSE_STATUS_DELETED)}
        onClose={confirmModal.close}
        isOpen={confirmModal.isActive}
        isLoading={isChangeLoading}
        title={t?.areYouSure}
        maxHeight={isMobileDevice() ? "270px" : '300px'}
      />
    </MainLayout>
  );
};

export default CoursesPage;
