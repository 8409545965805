import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import ToastMsg from "../components/ToastMsg/ToastMsg";
import { QUESTION_TYPES_DATA } from "../constants/courses";
import UserApi from "./api/UserApi";

export const LOADING_NOT_NEEDED = true;

export const RATING_TEN = 10;
export const RATING_FIVE = 5;

export const ONE_CHOICE_ANSWER_CODE = 0;
export const MULTIPLE_CHOICE_ANSWER_CODE = 1;
export const FILE_UPLOAD_ANSWER_CODE = 2;
export const WRITTEN_ANSWER_CODE = 3;
export const RATE_ANSWER_CODE = 4;
export const STARS_ANSWER_CODE = 5;
export const TRUE_FALSE_ANSWER_CODE = 6;
export const COMPARE_ANSWER_CODE = 7;
export const SEQUENCE_ANSWER_CODE = 8;
export const VIDEO_ANSWER_CODE = 9;

export const QUESTIONS_THAT_NEED_EXTRA_CREATION = [
      WRITTEN_ANSWER_CODE, 
      RATE_ANSWER_CODE, 
      STARS_ANSWER_CODE,
      VIDEO_ANSWER_CODE,
      FILE_UPLOAD_ANSWER_CODE
   ]

export const QUESTIONS_THAT_NEED_EXTRA_CREATION_PLUS_COMPARE = [
      WRITTEN_ANSWER_CODE, 
      RATE_ANSWER_CODE, 
      STARS_ANSWER_CODE,
      VIDEO_ANSWER_CODE,
      FILE_UPLOAD_ANSWER_CODE,
      COMPARE_ANSWER_CODE,
   ]

export const QUESTIONS_THAT_NOT_INCLUDED_IN_AUTO_CALCULATION = [
      WRITTEN_ANSWER_CODE, 
      VIDEO_ANSWER_CODE,
      FILE_UPLOAD_ANSWER_CODE
   ]

export const QUESTIONS_THAT_CAN_BE_AUTOCHECKED_AND_NOT_CHOOSEN = [
      ONE_CHOICE_ANSWER_CODE,
      MULTIPLE_CHOICE_ANSWER_CODE,
      TRUE_FALSE_ANSWER_CODE
   ]

export const ALLOWED_FILE_TYPES = [
   "mp4",
   "avi",
   "mov",
   "wmv",
   "flv",
   "webm",
   "mkv",
   "mpg",
   "mpeg",
   "3gp",
   "m4v"
]

export const questionsTypesWithoutExtraAnswers = [6, 3, 4, 5, 2, 9];

export const catchErrorsAndShowSuccess = (res, langName) => {
   const resultsData = res?.data;
   let hasErrors = false;
   let lastErrorMessage = "";

   const allQuestions = [];

   if (
      resultsData?.created_questions &&
      resultsData.created_questions.length > 0
   ) {
      allQuestions.push(...resultsData.created_questions);
   }

   if (
      resultsData?.updated_questions &&
      resultsData.updated_questions.length > 0
   ) {
      allQuestions.push(...resultsData.updated_questions);
   }

   allQuestions?.forEach((question, idx) => {
      if (question.error) {
         lastErrorMessage = `${question.error.message}`;
         // lastErrorMessage = `T ${idx + 1}: ${question.error.message}`;
         hasErrors = true;
      }
   });

   if (hasErrors) {
      return toast(<ToastMsg text={lastErrorMessage} isError />);
   } else {
      return toast(<ToastMsg text={`${res?.success?.message} (${langName} version)`} />);
   }
};

export function formatMilliseconds(milliseconds) {
   // Calculate hours, minutes, and seconds from milliseconds
   const seconds = Math.floor(milliseconds / 1000) % 60;
   const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
   const hours = Math.floor(milliseconds / (1000 * 60 * 60));
 
   const formattedTime = `PT${hours}H${minutes}M${seconds}S`;
   
   return formattedTime;
 }

export const showValidationErrors = (arr, skip) => {
   let hasErrors = false;
   let lastErrorMessage = "";

   arr?.forEach((question, idx) => {
      if (question.content === "" && question.type !== VIDEO_ANSWER_CODE) {
         lastErrorMessage = `Question title cannot be empty`;
         hasErrors = true;
      }

      if (question?.type === MULTIPLE_CHOICE_ANSWER_CODE) {
         const hasCorrectAnswer = question?.answers?.some(answer => answer.is_correct === 1);
     
         if (!hasCorrectAnswer) {
             lastErrorMessage = `At least one answer should be chosen`;
             hasErrors = true;
         }
     }

      if (question?.type === VIDEO_ANSWER_CODE) {
         if (!question?.title) {
             lastErrorMessage = `Question title cannot be empty`;
             hasErrors = true;
         }
     }

      question?.answers?.forEach((answer, answerIdx) => {
         if (answer.content === "") {
            lastErrorMessage = `Answers title cannot be empty`;
            // lastErrorMessage = `T ${idx + 1}: Answers title cannot be empty`;
            hasErrors = true;
         }
      });
   });

   if (hasErrors) {
      return lastErrorMessage;
   }

   return false;
};

export const getTypeText = (type) => {
   const typeTextMap = {
      0: "One choice",
      1: "Multiple choice",
      2: "File upload",
      3: "Written answer",
      4: "Rate 1-10",
      5: "Stars 1-10",
      6: "True/False",
      7: "Compare",
      8: "Sequence",
      9: "Video Answer",
   };

   return typeTextMap[type] || "";
};

export const getTypeImage = (type) => {
   const typeData = QUESTION_TYPES_DATA.find((data) => data.type === type);
   return typeData ? typeData.image : null;
};

export const defineQuestionDefaultObj = (type, id) => {
   const defaultQuestionData = {
      lesson_id: id,
      uniqueId: uuidv4(),
      type: type,
      content: "Default question",
      answers: [],
   };

   /*    Types codes and correspondence: 

          0: One choice,        5: Stars 1-10
          1: Multiple choice    6: True/False   
          2: File upload        7: Compare 
          3: Written answer     8: Sequence,  
          4: Rate 1-10          9: Video Answer 
      */

   const questionDefaults = {
      0: {
         content: "",
         answers: [
            { id: uuidv4(), content: "", isCorrect: 1 },
            { id: uuidv4(), content: "", isCorrect: 0 },
         ],
      },
      1: {
         content: "",
         answers: [
            { id: uuidv4(), content: "", isCorrect: 0 },
            { id: uuidv4(), content: "", isCorrect: 0 },
         ],
      },
      2: {
         content: "",
         filetype: ["pdf", "doc", "docx"],
         answers: [
            { id: uuidv4(), content: "Default content for file upload answer", isCorrect: 1 },
         ],
      },
      3: {
         content: "",
         answers: [
            { id: uuidv4(), content: "Type your answer", isCorrect: 1 },
         ],
      },
      4: {
         content: "",
         rating: 10,
         answers: [
            { id: uuidv4(), content: "Default content for rate answer", isCorrect: 1 },
         ],
      },
      5: {
         content: "",
         rating: 10,
         answers: [
            { id: uuidv4(), content: "Default content for stars answer", isCorrect: 1 },
         ],
      },
      6: {
         content: "",
         answers: [
            { id: uuidv4(), content: "True", isCorrect: 1 },
            { id: uuidv4(), content: "False", isCorrect: 0 },
         ],
      },
      7: {
         content: "",
         options: ["", ""],
         answers: [
            {
               id: uuidv4(),
               content: "",
               isCorrect: 1,
               matchingOption: 0,
            },
            {
               id: uuidv4(),
               content: "",
               isCorrect: 1,
               matchingOption: 1,
            },
         ],
      },
      8: {
         content: "",
         // ["A", "B", "C", "D"]
         sequence: [],
         answers: [
            { id: uuidv4(), content: "", isCorrect: 1 },
            { id: uuidv4(), content: "", isCorrect: 1 },
            { id: uuidv4(), content: "", isCorrect: 1 },
            { id: uuidv4(), content: "", isCorrect: 1 },
         ],
      },
      9: {
         title: "",
         answers: [
            { id: uuidv4(), content: "Default content for video answer", isCorrect: 1 },
         ],
      },
   };

   return {
      ...defaultQuestionData,
      ...questionDefaults[type],
   };
};


export const shuffleArray = (array) => {
   for (let i = array.length - 1; i > 0; i--) {
     const j = Math.floor(Math.random() * (i + 1));
     [array[i], array[j]] = [array[j], array[i]];
   }
 }

 export const shuffleArrayContent = (array, options) => {
   if (!array || !options || array.length !== options.length) {
       // Handle invalid input, if necessary
       return;
   }

   array.forEach((item, idx) => {
       item.new_content = array[item?.matchingOption]?.content;
   });
}

export const returnTemporaryQuestionFileLink = async(userId, name, courseId) => {
   const res = await new UserApi().replaceS3Links([{
      user_id: userId,
      filename: name,
      course_id: courseId
   }]);

   if(res?.success?.data) {
      return res?.success?.data?.map(item => ({
         fileUrl: item?.success?.data?.file_signed_url,
         fileName: item?.success?.data?.filename
      }));
   }
 }

 function getFileNameFromURL(url) {
   const parts = url.split('/');
   const filenameWithParams = parts[parts.length - 1];
   const filenameParts = filenameWithParams.split('?');
   return filenameParts[0];
}

 export const returnTemporaryQuestionFileLinks = async(userId, questions, courseId) => {
   const res = await new UserApi().replaceS3Links(questions?.map(q => ({
      user_id: userId,
      filename: getFileNameFromURL(q?.content)
   })));

   if(res?.success?.data) {
      return res?.success?.data?.map(item => ({
         fileUrl: item?.success?.data?.file_signed_url,
         fileName: item?.success?.data?.filename
      }));
   }
 }

 export const returnTemporaryAnswerFileLinks = async(userId, links, courseId) => {
   if(!links?.length) return;

   const res = await new UserApi().replaceS3Links(links?.map(link => ({
      user_id: userId,
      filename: getFileNameFromURL(link)
   })));

   if(res?.success?.data) {
      return res?.success?.data?.map(item => ({
         fileUrl: item?.success?.data?.file_signed_url,
         fileName: item?.success?.data?.filename
      }));
   }
 }

 export const returnTemporaryCommentsFileLinks = async(userId, comments, courseId) => {
   const res = await new UserApi().replaceS3Links(comments?.map(q => ({
      user_id: userId,
      filename: getFileNameFromURL(q?.filename),
      course_id: courseId
   })));

   if(res?.success?.data) {
      return res?.success?.data?.map(item => ({
         fileUrl: item?.success?.data?.file_signed_url,
         fileName: item?.success?.data?.filename
      }));
   }
 }